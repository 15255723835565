import { SyntheticEvent } from "react";
import { useParams } from "react-router-dom";
import { useContent } from "../../ContentProvider";
import { LIMITS_ON_THE_PAGE, POSITIONS_ADV, RECORD_FRAMES, SITES, TYPES_ADV } from "../../data/DEFAULT_DATA";
import { useRecords } from "../../routes/Records/RecordsProvider";
import CustomCalendar from "./CustomCalendar";
import Filter from "./Filter/Filter";
import Grouping from "./Grouping";
import ReloadClearButtons from "./ReloadClearButtons";
import style from "./Sorting.module.css";

export default function SortingPanel() {
    //@ts-ignore
    const { params, methods } = useRecords();
    const { lengthResponse, lenghtWithoutCondition, ObjSearchParams } = params;
    const { change_search_params } = methods;
    //@ts-ignore
    const { store, mainParameters } = useContent();
    const { changeSite } = store;
    const { adsSections, partitions } = mainParameters;

    const {
        table,
        limit,
        partition,
        frame,
        position,
        site,
        type_content,
        state,
        filter_period,
        subpartition,
    } = ObjSearchParams;

    const { path } = useParams();
    const isRecycle = path === "recycle";
    const isNews = path === "news";

    const onClick = (value: string, key: string) => change_search_params({ key, value: value.split("=")[0] });

    return (
        <div className={`${style.range_tablewithitems} sorting`}>
            <div className={style.row} title="Группировать">
                <div className={style.title}>Группировать</div>
                <Grouping
                    isNews={isNews}
                    onChange={(event: SyntheticEvent<HTMLSelectElement>) =>
                        change_search_params({ key: "value", event })
                    }
                />
                <Filter
                    options={LIMITS_ON_THE_PAGE}
                    selectedOption={limit}
                    prefix="Записей - "
                    allOption="Все записи"
                    title="Количество отображаемых записей"
                    onClick={(value) => onClick(value, "limit")}
                />
                <ReloadClearButtons />
            </div>
            <div className={style.row} title="Фильтровать">
                <div className={style.title}>Фильтровать</div>
                {!isRecycle && (
                    <Filter
                        options={["1=Запись ВКЛ", "0=Запись ВЫКЛ"]}
                        selectedOption={state}
                        title="Состояние записи"
                        onClick={(value) => onClick(value, "state")}
                    />
                )}
                {(isNews || isRecycle) && (
                    <Filter
                        options={SITES}
                        selectedOption={site}
                        title="Целевой сайт"
                        allOption="Все сайты"
                        onClick={(value) => {
                            onClick(value, "site");
                            changeSite(value);
                        }}
                    />
                )}
                {isRecycle && (
                    <Filter
                        options={["news=Новости", "adv=Реклама"]}
                        selectedOption={table}
                        title="Тип записи"
                        onClick={(value) => onClick(value, "table")}
                    />
                )}
                {isNews && partitions && (
                    <Filter
                        isNestedFilter
                        options={partitions}
                        selectedOption={subpartition !== "all" ? subpartition : partition}
                        selectedNestedOption={subpartition}
                        title="Выбрать целевой раздел"
                        allOption="Все разделы"
                        onClick={(value) => onClick(value, "partition")}
                        onClickSubpartition={(value, parent_name) => {
                            change_search_params({
                                value: value.split("=")[0],
                                parent_name: parent_name.split("=")[0],
                                key: "subpartition",
                            });
                        }}
                    />
                )}
                {!isNews && adsSections && (
                    <Filter
                        options={adsSections}
                        selectedOption={partition}
                        title="Выбрать целевой раздел"
                        allOption="Все разделы"
                        onClick={(value) => onClick(value, "partition")}
                    />
                )}
                {!isNews && (
                    <Filter
                        options={TYPES_ADV}
                        selectedOption={type_content}
                        allOption="Все типы"
                        prefix="Тип - "
                        title="Тип рекламы"
                        onClick={(value) => onClick(value, "type_content")}
                    />
                )}
                {isNews && (
                    <Filter
                        options={RECORD_FRAMES}
                        selectedOption={frame}
                        allOption="Все рамки"
                        title="Рамка главного медиафайла"
                        prefix="Рамка - "
                        onClick={(value) => onClick(value, "frame")}
                    />
                )}
                {isNews && (
                    <Filter
                        options={["statistics=Охват", "records=Записи"]}
                        selectedOption={filter_period}
                        title="Фильтр по периоду"
                        prefix="Период - "
                        onClick={(value) => onClick(value, "filter_period")}
                    />
                )}
                {!isNews && POSITIONS_ADV && (
                    <Filter
                        options={POSITIONS_ADV}
                        selectedOption={position}
                        title="Выбрать позицию баннера"
                        allOption="Все позиции"
                        onClick={(value) => onClick(value, "position")}
                    />
                )}
            </div>
            <div className={`${style.row} ${style.child_range} ${style.child_params}`}>
                Отображено {lengthResponse || 0} из {lenghtWithoutCondition || 0}
            </div>
            {isNews && <CustomCalendar {...{ filter_period }} />}
        </div>
    );
}
