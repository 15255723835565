import { Link, useLocation } from "react-router-dom";
import style from "./Input.module.css";
import { useState } from "react";
import $ from "jquery";
import { useContent } from "../../ContentProvider";

function Keywords({ searchdata, onClick, isActions = true }) {
    let { pathname } = useLocation();
    pathname = pathname?.split("/")[1] || "home";

    const { mainParameters } = useContent();
    const { keywords } = mainParameters;

    const [isView, setIsView] = useState(false);

    isActions &&
        (function (elementid) {
            $(function () {
                function scrollHorizontally(e) {
                    e = window.event || e;
                    var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
                    document.getElementById(elementid).scrollLeft -= delta * 10; // Multiplied by 10
                    e.preventDefault();
                }
                if (document.getElementById(elementid)?.addEventListener) {
                    // IE9, Chrome, Safari, Opera
                    document
                        .getElementById(elementid)
                        ?.addEventListener("mousewheel", scrollHorizontally, false);
                    // Firefox
                    document
                        .getElementById(elementid)
                        ?.addEventListener("DOMMouseScroll", scrollHorizontally, false);
                } else {
                    // IE 6/7/8
                    document.getElementById(elementid)?.attachEvent("onmousewheel", scrollHorizontally);
                }
            });
        })("row_keywords");

    function onClickSearchValues(e) {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        const arrParams = searchdata?.split(" ");
        const lastIndex = arrParams?.length - 1;
        const lastElement = arrParams[lastIndex]?.trim();

        if (!lastElement) return typeof onClick === "function" && onClick(e.target.innerText);

        arrParams.splice(lastIndex, 1);
        arrParams.push(e.target.innerText);
        /* console.log(arrParams); */
        typeof onClick === "function" && onClick(arrParams.join(" "));
    }
    function mapKeywords(keywordsArray = [], isLink = true, className = "") {
        return keywordsArray?.map((element, index) => {
            const { keyword = false, count = 0 } = element || {};
            return (
                keyword && (
                    <div key={index} className={style.keyword_container}>
                        <Link
                            title={
                                isLink ? `Перейти к записям по тегу "${keyword}"` : `Вставить "${keyword}"`
                            }
                            to={`/news?search=${keyword}`}
                            onClick={(e) => {
                                if (isLink) return;
                                onClickSearchValues(e);
                            }}
                            name="keywords"
                            data-path={pathname}
                            className={`${className} ${style.keywords_element}`}
                        >
                            {keyword}
                        </Link>
                        <span title="Количество записей по тегу">{count}</span>
                    </div>
                )
            );
        });
    }
    function searchValues(searchparam = "", arrayvalues = []) {
        const newArray = [...arrayvalues];
        const arrParams = searchparam?.split(" ");
        const lastElement = arrParams[arrParams?.length - 1];

        if (!lastElement.trim()) return;

        const find = function (arr, find) {
            return arr.filter(function (value) {
                const { keyword } = value;
                const regexp = new RegExp(`${find}`, "gi");
                return keyword.match(regexp);
            });
        };
        const findedElements = find(newArray, lastElement) || [];
        return mapKeywords(findedElements, false, style.findedElement);
    }
    return (
        <>
            <span className={style.insc}>1.Ключевые слова</span>
            <div className={style.keywords}>
                <div id="row_keywords" className={style.keywords_search_row}>
                    {searchValues(searchdata, keywords)}
                </div>
                <button
                    className={style.keywords_view_btn}
                    onClick={() => {
                        setIsView(!isView);
                    }}
                >
                    {isView ? "Скрыть" : "Отобразить все ключевые слова"}
                </button>
                <div className={`${style.keywords_meta} ${isView ? style.keywords_meta_view : ""}`}>
                    <div className={style.keywords_title}>Существующие ключевые слова:</div>
                    <div className={style.keywords_wrapper}>{isView && mapKeywords(keywords, false)}</div>
                </div>
            </div>
        </>
    );
}
export default Keywords;
