export function deepEqual(x, y, isStrict = true) {
    const ok = Object.keys,
        tx = typeof x,
        ty = typeof y;

    return x && y && tx === "object" && tx === ty
        ? ok(x).length === ok(y).length && ok(x).every((key) => deepEqual(x[key], y[key]))
        : isStrict
        ? x === y
        : x == y;
}
export function deepToString(o) {
    Object.keys(o).forEach((k) => {
        if (typeof o[k] === "object") {
            return toString(o[k]);
        }
        o[k] = "" + o[k];
    });
    return o;
}

export function stringObjectEqual(x, y) {
    if (typeof x !== "object" || typeof y !== "object") return false;
    let z = x,
        w = y;
    z = JSON.stringify(deepToString(z));
    w = JSON.stringify(deepToString(w));

    return z === w;
}
