import { useState } from "react";
import style from "./Partitions.module.scss";
import { DEFAULT_PARAMS_PARTITIONS } from "./defaults";
import AddPartition from "./elements/AddPartition";
import EditButtons from "./elements/EditButtons";
import HeadPartitions from "./elements/HeadPartitions";
import Partition from "./elements/Partition";
import PartitionsIsEmpty from "./elements/PartitionsIsEmpty";
import { TParamsPartitions, TPartition, TSubpartition } from "./partitions.interface";

interface IPartitionsWrapper {
    data: TPartition[] | TSubpartition[];
    title: string;
    titleInput: string;
    table: string;
    fetching: boolean;
    parent_id?: string;
    isInfoContainer?: boolean;
    isSubpartitions?: boolean;

    isAddContainerAlwaysOpened?: boolean | null;
}

export type SubpartFeatures = {
    parent_id: string | false;
    add_container_opened: boolean;
};

export type onChangeSubpartFeatures = {
    key: string;
    state: string | boolean;
};

export default function PartitionsWrapper({
    data,
    fetching,
    table,
    parent_id,
    titleInput,
    isSubpartitions = true,
    title,
    isInfoContainer = true,
    isAddContainerAlwaysOpened,
}: IPartitionsWrapper) {
    const [subpartFeatures, setSubpartFeatures] = useState<SubpartFeatures>({
        parent_id: false,
        add_container_opened: false,
    });

    const onChangeSubpartFeatures = ({ key, state }: onChangeSubpartFeatures) => {
        setSubpartFeatures((prevstate) => ({
            ...prevstate,
            [key]: state,
        }));
    };

    const [addVisible, setAddVisible] = useState(false);
    const [paramsPartitions, setParamsPartitions] = useState<TParamsPartitions>({
        ...DEFAULT_PARAMS_PARTITIONS,
        table,
        parent_id,
    });
    // val input add partition
    const [value, setValue] = useState("");
    // set content editable
    const [isEdit, setIsEdit] = useState(false);
    //on restore to active state target partition
    const [isRestore, setIsRestore] = useState(false);
    //callback message of the end response partitions
    const [isSaveParts, setIsSaveParts] = useState<string | boolean>(false);

    return (
        <div className={style.partitions_creator_wrapper}>
            {isSaveParts && <span className={style.pe_save}>{isSaveParts}</span>}
            <div className={style.partitions_wrapper}>
                <div className={style.top_pw}>
                    <HeadPartitions title={title} isInfoContainer={isInfoContainer} />

                    <EditButtons
                        paramsPartitions={paramsPartitions}
                        isEdit={isEdit}
                        isRestore={isRestore}
                        isSaveParts={isSaveParts}
                        {...{
                            setParamsPartitions,
                            setValue,
                            setIsEdit,
                            setIsRestore,
                            onAdd: () => {
                                if (typeof isAddContainerAlwaysOpened === "boolean") {
                                    onChangeSubpartFeatures({
                                        key: "add_container_opened",
                                        state: !subpartFeatures.add_container_opened,
                                    });
                                } else setAddVisible(!addVisible);
                            },
                            onDelete: () => {
                                setIsRestore(!isRestore);
                                setIsEdit(false);
                            },
                            onEdit: () => {
                                setIsEdit(!isEdit);
                                setIsRestore(false);
                            },
                        }}
                    />
                </div>

                <div className={style.partitions_block}>
                    {(data.length === 0 && !fetching) || (parent_id && data.length === 0) ? (
                        <PartitionsIsEmpty
                            className={`${style.partition_elem} ${
                                isEdit || isRestore ? style.partition_elem_editable : ""
                            }`}
                        />
                    ) : (
                        data.map((partition) => (
                            <Partition
                                key={partition.id}
                                paramsPartitions={paramsPartitions}
                                isEdit={isEdit}
                                isRestore={isRestore}
                                subpartitions_params={{
                                    isActive: isSubpartitions ? !isEdit : false,
                                    subpartFeatures,
                                    onChangeSubpartFeatures,
                                }}
                                partition={partition}
                                setParamsPartitions={setParamsPartitions}
                            />
                        ))
                    )}
                </div>
            </div>

            {(typeof isAddContainerAlwaysOpened === "boolean" ? isAddContainerAlwaysOpened : addVisible) && (
                <AddPartition
                    title={titleInput}
                    value={value}
                    paramsPartitions={paramsPartitions}
                    onChange={(e) => setValue(e.currentTarget.value)}
                    setIsSaveParts={setIsSaveParts}
                />
            )}
        </div>
    );
}
