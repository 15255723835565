import React from "react";
import styles from "./Input.module.css";
import { useState } from "react";
import axios from "axios";

const protocol = "https";
const folder_scripts = "scripts/";
const url_admin = `${protocol}://club.clubok24.ru/`;
const url = `${url_admin}${folder_scripts}pointUpdate.php`;

function InputNote({ value, table, id, column }) {
    const [note, setNote] = useState(value || "");

    function onChangeNoteInput(e) {
        const v = e.target.value;
        const fd = new FormData();
        fd.append("textData", JSON.stringify({ value: v, column, table, id }));
        axios.post(url, fd);

        setNote(v);
    }

    return (
        <textarea
            value={note}
            placeholder="Заметка отсутствует"
            onChange={onChangeNoteInput}
            className={styles.note}
        />
    );
}

export default InputNote;
