export function currentDateTime(type) {
    const date = new Date();
    const dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
    const MM = (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1);
    const yyyy = date.getFullYear();
    const ddmmyyyy = `${yyyy}-${MM}-${dd}`;
    const hh = (date.getHours() < 10 ? "0" : "") + date.getHours();
    const mm = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    const hhmm = `${hh}:${mm}`;

    switch (type) {
        case "date":
            return ddmmyyyy;
        case "datetime":
            return hhmm;
        case "all":
        default:
            return `${ddmmyyyy} ${hhmm}:${String(date.getSeconds()).padStart("2", "0")}`;
    }
}
export function datediff(first, second) {
    if (!first || !second) return;
    const oneday = 1000 * 60 * 60 * 24;
    const diff = second.getTime() - first.getTime(); //ms

    if (diff === 0) {
        console.log(second, first);
    } else return Math.ceil(diff / oneday);
}

export function getDate(date, type) {
    if (!date) return;
    if (typeof date.getMonth !== "function") return;

    switch (type) {
        case "date":
            const dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
            const MM = (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1);
            const yyyy = date.getFullYear();
            const ddmmyyyy = `${dd}.${MM}.${yyyy}`;
            return ddmmyyyy;
        case "datetime":
            const hh = (date.getHours() < 10 ? "0" : "") + date.getHours();
            const mm = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
            const hhmm = `${hh}:${mm}`;
            return hhmm;
        default:
            break;
    }
}
