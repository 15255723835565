import style from "./Partitions.module.scss";

export const row = (string: string) => <div className={style.row_in_desc}>{string}</div>;
export function info_about_colors(color: `#${string}`, description: string) {
    return (
        <div className={style.info_child}>
            <span style={{ backgroundColor: color }} className={style.info_color}></span>
            <span className={style.info_desc}>- {description}</span>
        </div>
    );
}

export function validatePartition(row: string | null | undefined): string | boolean {
    if (!row || !row.trim()) return "Имя раздела не может быть пустым";
    if (row.length < 3) return "Длина раздела не может быть менее 3 символов";
    if (/[="',]/.test(row)) return "Имя раздела содержит недопустимые символы = \" ' ,";

    return true;
}
