import { SyntheticEvent } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import styles from "./Filter.module.scss";
interface IOption {
    value: string;
    title: string;
    isCurrent?: boolean;
    className?: string;

    arrow?: {
        onClick?: (e: SyntheticEvent<HTMLDivElement>) => void;
    };
    onClick?: (value: string) => void;
}

export default function Option({ value, title, arrow, className, onClick }: IOption) {
    return (
        <div className={`${styles.option} ${className || ''}`} data-value={value} onClick={() => onClick && onClick(value)}>
            <div className={styles.title_option}>{title}</div>
            {arrow && (
                <div className={styles.arrow_container} onClick={arrow.onClick}>
                    <IoMdArrowDropdown className={styles.arrow} />
                </div>
            )}
        </div>
    );
}
