import { FastAverageColor } from "fast-average-color";
import $ from "jquery";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UploadProgress from "../../components/UploadProgress";
import { useContent } from "../../ContentProvider";
import style from "../../css/Pages.module.css";
import { DEFAULT_DATA, DEFAULT_PARAMS_SORTING } from "../../data/DEFAULT_DATA";
import URL_COLLECTION from "../../data/URL";
import { currentDateTime } from "../../functions/DateTime";
import { deepToString } from "../../functions/Objects";
import transliterate from "../../functions/transliterator";
import { unique_hash_name } from "../../functions/uniqueName";
import Paths from "./Paths";

const CreateContext = createContext(null);
export const useCreate = () => useContext(CreateContext);

let timer = null;

export default function CreateProvider() {
    const navigate = useNavigate();
    let { pathname } = useLocation();
    pathname = pathname?.split("/")[1] || "home";

    const { store } = useContent();
    const { blackout, message } = store;

    const [data, setData] = useState(DEFAULT_DATA);
    const [files, setFiles] = useState([]);
    const [replacePool] = useState([]);
    const [historyTimestamp, setHistoryTimestamp] = useState(
        localStorage.getItem(`${pathname}_timestamp`) || "данные отсутствуют."
    );

    const initialRefData = useRef(null);
    initialRefData.current = data;

    let requestPool = [];
    let dataPool = [];
    let prevPercent = 0;
    let countPackage = 0;

    async function _averageColor(resourse, pathname) {
        const ac = await new FastAverageColor()
            .getColorAsync(resourse)
            .then((res) => res.hex)
            .catch(() => "none");
        onChangeData({ value: ac, name: "averageColor", pathname });
    }
    // checking and sending data (text and multimedia) to the server
    function _upload(dataForSend, type, chunkId) {
        //TODO: ПЕРЕДЕЛАТЬ
        function clearValueElements() {
            prevPercent = 0;
            countPackage = 0;
            percentageOfProgress.value = 0 + "%";
            percentageOfProgress.textContent = 0 + "%";
            progressBar.style.strokeDashoffset = 342;
            const target_search_params = structuredClone(DEFAULT_PARAMS_SORTING);
            let tablename = "news";
            switch (pathname) {
                case "createnews":
                    tablename = "news";
                    target_search_params.table = "news";
                    target_search_params.site = data["createnews"].site.split("=")[0];
                    target_search_params.partition = data["createnews"].partition.split("=")[0];
                    target_search_params.frame = data["createnews"].isVideoFrame;
                    break;
                case "createadv":
                    tablename = "adv";
                    target_search_params.table = "adv";
                    target_search_params.partition = data["createadv"].partition.split("=")[0];
                    target_search_params.type_content = data["createadv"].is_trash;
                    target_search_params.position = Array.isArray(data["createadv"].position)
                        ? data["createadv"].position[0]
                        : data["createadv"].position.split("=")[0];
                    break;
                default:
                    break;
            }
            navigate(`/${tablename}?${$.param(deepToString(target_search_params))}`);
            clearData(pathname);
        }

        function errorSendingToServer() {
            prevPercent = 0;
            percentageOfProgress.value = 0 + "%";
            percentageOfProgress.textContent = 0 + "%";
            progressBar.style.strokeDashoffset = 342;
        }
        const zeroToOne = (number) => (number === 0 ? (number = 1) : number);
        const progressBar = document.getElementsByClassName(style.progress)[0];
        const percentageOfProgress = document.getElementsByClassName(style.percentageOfProgress)[0];
        const descriptionOfProgress = document.getElementsByClassName(style.popup_desc)[0];

        const fd = new FormData();
        const xhr = new XMLHttpRequest();

        xhr.onerror = () => {
            message("Ошибка загрузки записи.", "ERRRQ", "ОШИБКА:", 1);
            blackout(style.popup, 0, 0, "hidden");
            errorSendingToServer();
        };

        switch (type) {
            case "text":
                xhr.open("POST", URL_COLLECTION.scripts.main.set_text, true);

                if (data.currentItem.id !== "" && data.currentItem.mediaReplace === "noreplace") {
                    xhr.onload = () => {
                        xhr.readyState === 4 && clearValueElements();
                        message(
                            `Изменения в запись №${data.currentItem.id} внесены успешно`,
                            "OK",
                            "Изменения:",
                            -1
                        );
                    };
                }

                fd.append("newText", JSON.stringify(dataForSend));
                fd.append("type", JSON.stringify(type));
                fd.append(
                    "isReplace",
                    JSON.stringify({
                        id: data.currentItem.id,
                        replaceMedia: data.currentItem.mediaReplace,
                        replacePool: replacePool.join(","),
                    })
                );
                break;
            case "files":
                xhr.open("POST", URL_COLLECTION.scripts.main.set_media, true);

                xhr.onload = () => {
                    countPackage++;
                    switch (xhr.status) {
                        case 200:
                            if (countPackage === requestPool.length) {
                                countPackage = 0;
                                blackout(style.popup, 0, 0, "hidden");
                                if (["OK", "success"].entries(xhr.responseText)) {
                                    message("Данные успешно загружены на сервер!", "OK", "Запись:", -1);
                                    clearValueElements();
                                }
                                if (xhr.responseText === "ERROR")
                                    message(`Ошибка загрузки данных.`, "200", "Запись:", 1);
                                descriptionOfProgress.innerHTML =
                                    "Идёт отправка...<br />Пожалуйста, подождите...";
                            }
                            break;
                        default:
                            blackout(style.popup, 0, 0, "hidden");
                            errorSendingToServer();
                            message(`Ошибка загрузки медиа`, "ERR", "Critical", 1);
                            descriptionOfProgress.innerHTML =
                                "Идёт отправка...<br />Пожалуйста, подождите...";
                            break;
                    }
                };
                xhr.upload.onprogress = function (e) {
                    const loaded = e.loaded;
                    let index = chunkId ? chunkId.split("_") : 0;
                    let progress = Math.ceil(
                        ((loaded * zeroToOne(index[1]) * zeroToOne(index[3])) / index[5]) * 100
                    );

                    if (progress <= 100 && prevPercent <= progress) {
                        prevPercent = progress;
                        percentageOfProgress.value = prevPercent + "%";
                        percentageOfProgress.textContent = prevPercent + "%";
                        progressBar.style.strokeDashoffset = 342 - prevPercent * 3.42;
                    }
                    if (progress >= 98)
                        descriptionOfProgress.innerHTML =
                            "Конвертация медиафайлов...<br /><span style='font-size:12px; margin: 0; padding: 0'>(от 1 секунды до 5 минут)</span><br />";
                };
                requestPool.push(xhr);
                dataPool.push(chunkId);
                fd.append("newFiles", dataForSend, `${chunkId}`);
                fd.append("table", pathname);
                break;
            default:
                break;
        }
        xhr.send(fd);
    }
    function _createFormToSeningOnServer(path) {
        //сборка формы для отправки. осуществляется после валидации
        _upload(data[path], "text", false);

        let progressTotal = 1;
        //пропускает отправку, если файлы не загружены на сервер
        if (data.currentItem.mediaReplace === "replace" || data.currentItem.id === "") {
            const BYTES_PER_CHUNK = 1024 * 1024 * 7; // 7Mбайт или 7 340 032 байт -- ограничение сервера
            const lengthFiles = Object.keys(files).length;
            for (let i = 0; i < lengthFiles; i++) {
                progressTotal += files[i].size;
            }
            for (let i = 0; i < lengthFiles; i++) {
                const SIZE = files[i].size;
                const blob = new Blob([files[i]]);
                const hash = data[path]["md5"][0].split(",")[i];

                let indexChunk = 0;
                let lengthChunks = Math.ceil(SIZE / BYTES_PER_CHUNK);
                let start = 0;
                let end = BYTES_PER_CHUNK;

                while (start < SIZE) {
                    const chunkId = `${hash}_${lengthFiles}_${i}_${lengthChunks}_${indexChunk}_${progressTotal}`; //имя чанка
                    let chunk = blob.slice(start, end);

                    start = end;
                    end = start + BYTES_PER_CHUNK;
                    _upload(chunk, "files", chunkId);
                    indexChunk++;
                }
                blackout(style.popup, 0, 1, "visible");
            }
        }
    }

    function _formAddError(input) {
        const parentMedia = input.classList.contains("media");
        if (parentMedia) {
            input.parentElement.classList.add(style._error);
        }
        input.classList.add(style._error);
    }
    function _formRemoveError(input) {
        input.parentElement.classList.remove(style._error);
        input.classList.remove(style._error);
    }
    // events of the inputs and buttons
    function abortSending() {
        const requestDelete = new XMLHttpRequest();

        const id_data = data[pathname].md5 || data[pathname].media_links;
        const url_delete_recordWithParams = new URL(
            `${URL_COLLECTION.scripts.main.recycle_bin}?method=md5&table=${data[pathname].path}&id=${id_data}&action=-1`
        );
        const progressBar = document.getElementsByClassName(style.progress)[0];
        const percentageOfProgress = document.getElementsByClassName(style.percentageOfProgress)[0];

        requestDelete.open("GET", url_delete_recordWithParams, true);
        requestDelete.send();

        message("Отправка данных отменена.", "OK", "Отмена:", 0);
        blackout(style.popup, 0, 0, "hidden");

        requestPool.forEach((request) => request.abort());
        prevPercent = 0;
        countPackage = 0;
        percentageOfProgress.value = 0 + "%";
        percentageOfProgress.textContent = 0 + "%";
        progressBar.style.strokeDashoffset = 342;
    }
    function formValidate(path) {
        let errors = false;
        requestPool = [];
        const lengthFiles = Object.keys(files).length;
        const lengthLinks = data[path]?.media_links?.length || 0;
        $("._req").each(function (index, element) {
            const name = element?.getAttribute("name") || element?.getAttribute("data-name");
            _formRemoveError(element);

            switch (name) {
                case "media":
                    if (lengthLinks === 0 && lengthFiles === 0) {
                        _formAddError(element);
                        errors = true;
                    }
                    break;
                default:
                    const val = data[path][name];
                    if (!val) {
                        _formAddError(element);
                        errors = true;
                    }
                    break;
            }
        });
        if (!errors) _createFormToSeningOnServer(path);
    }
    function clearData(pathname) {
        const formReq = document.querySelectorAll("._req");
        for (let i = 0; i < formReq.length; i++) {
            const input = formReq[i];
            if (input.classList.contains(style._error)) {
                _formRemoveError(input);
            }
        }
        setFiles([]);
        setData(DEFAULT_DATA);
        return true;
    }
    function onDropFiles({ addToData = true, acceptedFiles, pathname }) {
        if (addToData) {
            const nameEvent = "md5";
            const hashPool = [];

            const lengthFiles = Object.keys(acceptedFiles).length;
            for (let i = 0; i < lengthFiles; i++) {
                const hash = unique_hash_name(acceptedFiles, i);
                hashPool.push(hash);
                if (i + 1 === lengthFiles) {
                    setData((prevState) => ({
                        ...prevState,
                        [pathname]: {
                            ...prevState[pathname],
                            [nameEvent]: [`${hashPool}`],
                        },
                    }));
                }
            }
            acceptedFiles.forEach((file, index) => (file.id = hashPool[index]));
            _averageColor(acceptedFiles[0].preview, pathname);
        }
        setFiles(acceptedFiles);
    }
    function onDeleteMedia({ event, pathname = "" }) {
        const { id, name } = event.target.dataset;
        let md5_filter = [];

        if (!id) {
            //local mediadata
            const index_file = files.map((e) => e.name).indexOf(name);
            const md5_local_id = files[index_file].id;
            const md5_local_array = data[pathname].md5[0].split(",");
            md5_filter = md5_local_array.filter((id) => id !== md5_local_id);
        } else {
            //downloaded
            const md5_server_array = data[pathname].md5[0].split(",");
            md5_filter = md5_server_array.filter((name_md5) => name_md5 !== id);
            replacePool.push(id);
        }

        setData((prevState) => ({
            ...prevState,
            [pathname]: {
                ...prevState[pathname],
                md5: `${md5_filter}`,
            },
        }));
        setFiles(files.filter((file) => file.name !== name));
    }
    function onChangeData({
        event,
        value,
        name,
        dublicateName,
        dublicateValue = null,
        pathname,
        replace = true,
        clearDublicate = false,
        type = false,
    }) {
        let replacedValue = value;

        if (event?.target || event?.currentTarget) {
            value = event.target.value !== undefined ? event.target.value : event.target.textContent;
            if (type === "select_boolean") {
                replacedValue = event.target.checked !== undefined ? event.target.checked : value;
                replacedValue = replacedValue ? 1 : 0;
            } else replacedValue = value;
        } else replacedValue = typeof event === "string" ? event : value;

        switch (type) {
            case "transliterated_string":
                dublicateValue = transliterate(value || "").toLowerCase();
                break;
            case "add_value":
                const arrValues = (data[pathname][name] || "").split(" ");
                arrValues[arrValues.length - 1] = replacedValue + " ";
                replacedValue = arrValues.join(" ");
                break;
            default:
                break;
        }

        if (!replace) {
            dublicateValue = dublicateName ? data[pathname][dublicateName] + " " + dublicateName : "";
            replacedValue = name ? data[pathname][name] + " " + replacedValue : "";
        }
        setData((prevState) => ({
            ...prevState,
            [pathname]: {
                ...prevState[pathname],
                [dublicateName]: clearDublicate ? "" : dublicateName && dublicateValue,
                [name]: name && replacedValue,
            },
        }));
        if (timer === null) timer = setInterval(() => savingStorageContent(initialRefData.current), 5000);
    }
    /* history effect */
    async function savingStorageContent(initialData) {
        if (initialData.currentItem.id) return;
        const timestamp = currentDateTime();
        setHistoryTimestamp(timestamp);

        localStorage.setItem(`${pathname}_text`, JSON.stringify(initialData[pathname]));
        localStorage.setItem(`${pathname}_timestamp`, timestamp);
    }
    function getSavingStorageContent() {
        const historyData = localStorage.getItem(`${pathname}_text`);
        if (!historyData) return message(`Запись по ключу '${pathname}_text' отсутствует`, "ОШИБКА", "", 0);

        setData((prevState) => ({
            ...prevState,
            [pathname]: JSON.parse(historyData),
        }));
    }
    useEffect(() => {
        return () => clearInterval(timer);
    }, []);

    const creator = {
        abortSending,
        formValidate,
        clearData,
        onChangeData,
        onDeleteMedia,
        onDropFiles,
    };

    return (
        <CreateContext.Provider value={{ creator, data, files }}>
            <UploadProgress />
            <div className={style.paths_page}>
                {pathname !== "home" && (
                    <button
                        className={style.get_item_localstorage_btn}
                        type="button"
                        title={`Вернуть последнюю запись.\nДата последнего сохранения — ${historyTimestamp}`}
                        onClick={getSavingStorageContent}
                    />
                )}
                <Paths />
            </div>
        </CreateContext.Provider>
    );
}
