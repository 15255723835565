import axios from "axios";
import { useMemo, useEffect, useState } from "react";
import style from "../css/Comments.module.css";
import stylePages from "../css/Pages.module.css";
import URL_COLLECTION from "../data/URL";

export default function Comments(props) {
    const url_comments = URL_COLLECTION.scripts.main.comments;
    const [comments, setComments] = useState([]);

    useEffect(() => requestToComments(), []);

    const onDeleteComment = (id) => requestToComments("remove", id);

    function requestToComments(type = "get", id) {
        const isId = id ? `&id=${id}` : "";
        axios
            .get(`${url_comments}?type=${type}${isId}`)
            .then((res) => setComments(Object.values(res.data || {})));
    }

    const memoComments = useMemo(() => {
        return comments?.map((comment, index) => {
            const {
                id,
                news_id,
                name_comment,
                message_comment,
                email,
                timestamp,
                likes,
                disslikes,
                link,
                part,
                site,
                parent_id,
            } = comment || {};

            const category = part ? part.split("=")[0].toLowerCase() : "";
            return (
                <div
                    id={`comment_${id}`}
                    className={style.comment_wrapper}
                    data-id={id}
                    data-news_id={news_id}
                    key={index}
                >
                    <div className={style.comment_area}>
                        <div className={style.user_name_date}>
                            <div className={style.user_name}>{name_comment}</div>{" "}
                            <div className={style.id_comment}>№{id}</div>
                            <div className={style.date}>{timestamp}</div>
                            <div className={style.like_disslike}>
                                <div className={style.likes}>
                                    Like: <span>{likes}</span>
                                </div>
                                <div className={style.disslikes}>
                                    Diss: <span>{disslikes}</span>
                                </div>
                            </div>
                            <a href={"//" + site} target="_blank" className={style.site} title="Сайт записи">
                                {site}
                            </a>
                            {parent_id && (
                                <div>
                                    Ответ на комментарий № <a href={`#comment_${parent_id}`}>{parent_id}</a>
                                </div>
                            )}
                        </div>
                        <div className={style.comment_content}>{message_comment}</div>
                        <div className={style.user_email}>
                            {email === "none" ? "E-mail отсутствует" : email}
                        </div>

                        <a
                            href={`https://${site}/category/${category}/${link}#${id}_idc_6_idp`}
                            target="_blank"
                            className={style.news_id}
                        >
                            Запись № <span>{news_id}</span>
                        </a>
                    </div>
                    <div
                        onClick={() => onDeleteComment(id)}
                        className={style.remove}
                        title="Удалить комментарий"
                    >
                        <span></span>
                        <span></span>
                    </div>
                </div>
            );
        });
    }, [comments]);
    return (
        <>
            <div className={stylePages.title_opt}>Комменнтарии пользователей</div>
            <div className={style.comments_wrapper}>{memoComments}</div>
        </>
    );
}
