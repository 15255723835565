import { useState } from "react";
import { info_about_colors, row } from "../partitions.helpers";
import style from "../Partitions.module.scss";

interface IHeadPartitions {
    title: string;
    isInfoContainer?: boolean;
}

export default function HeadPartitions({ title, isInfoContainer }: IHeadPartitions) {
    //info description btn
    const [isInfo, setIsInfo] = useState(false);

    return (
        <>
            <div className={style.insc}>
                <span className={style.insc_title}>{title}</span>
                {isInfoContainer && (
                    <span className={style.pe_info_btn} onClick={() => setIsInfo(!isInfo)}></span>
                )}
            </div>

            {isInfoContainer && (
                <>
                    <div className={`${style.info_description} ${isInfo ? style.info_isInfo : ""}`}>
                        {row(
                            `1. Если в момент удаления рубрики(раздела) с ней не будет связанных записей, то она будет
                    удалена безвозвратно, иначе - отключена с возможностью восстановления`
                        )}
                        {row(
                            `2. Количество связанных записей отображается в правом верхнем углу рубрики(раздела).`
                        )}
                        {row(`3. При изменении названия рубрики(раздела), меняется и название
            рубрики(раздела) связанных с ней записей. Также меняется и ссылка, по 
            которой можно перейти к рубрике, и, соответственно к записи.`)}
                        {row(`4. Количество возможных рубрик неограниченно.`)}
                        {row(`5. Максимальная длина названия рубрики(раздела) - 200 символов.`)}
                    </div>
                    <div className={style.info_wrapper}>
                        {info_about_colors("#90A5C2", "активная")}
                        {info_about_colors("#000000", "не активная")}
                        {info_about_colors("#215399", "доступна для редактирования")}
                        {info_about_colors("#FF0000", "отключаемая")}
                        {info_about_colors("#20C01A", "включаемая")}
                    </div>
                </>
            )}
        </>
    );
}
