import sending from "../images/sending.png";
import sending_2 from "../images/sending_2.png";
import stopSending from "../images/stop.png";
import style from "../css/Pages.module.css";
import { useCreate } from "../routes/Creator/CreateProvider";

const UploadProgress = ({}) => {
    const { creator } = useCreate();
    const { abortSending } = creator;

    return (
        <div id={style.popup} className={style.popup}>
            <div className={style.popup_body}>
                <div className={style.popup_content}>
                    <img
                        className={style.popup_stop}
                        src={stopSending}
                        title="Остановить отправку"
                        alt="Здесь могла быть ваша реклама..."
                        onClick={() => abortSending()}
                    ></img>
                    <img
                        className={style.popup_image}
                        src={sending}
                        alt="Здесь могла быть ваша реклама..."
                    ></img>
                    <img
                        className={style.popup_image_}
                        src={sending_2}
                        alt="Здесь тоже могла быть ваша реклама..."
                    ></img>
                    <div className={style.progressBar}>
                        <div className={style.percentageOfProgress}>0%</div>
                        <div className={style.round}>
                            <svg viewBox="0 0 120 122">
                                <circle className={style.bg} cx="57" cy="57" r="52" />
                                <circle className={style.progress} cx="64" cy="58" r="52" />
                            </svg>
                        </div>
                    </div>
                    <span className={style.popup_desc}>
                        Идёт отправка...
                        <br />
                        Пожалуйста, подождите...
                    </span>
                </div>
            </div>
        </div>
    );
};
export default UploadProgress;
