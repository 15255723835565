export default function averageValueRating(detailRating = []) {
    if (!detailRating) return false;
    const lengthDetailRating = detailRating?.length || 1;

    const initialValue = 0;
    const sumWithInitial = detailRating?.reduce(
        (accumulator, currentValue) => accumulator + parseInt(currentValue?.score, 10) || 1,
        initialValue
    );
    return (sumWithInitial / lengthDetailRating)?.toFixed(1);
}