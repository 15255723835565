import MD5 from "crypto-js/md5";

function unique_hash_name(el, i) {
    const timestamp = new Date().getTime().toString();
    const format = el[i]?.type?.split("/")[1] || 'webp';
    const hash =
        MD5(el[i].name + el[i].size + el[i].type) + "!" + timestamp + "." + format;
    return hash;
}
export { unique_hash_name };
