import { useRef } from "react";
import style from "../css/Pages.module.css";

const Popup = ({}) => {
    const popupRef = useRef();

    return (
        <div
            id="popup_adcl24"
            ref={popupRef}
            data-error={0}
            className={style.popupResponse}
            onClick={() => {
                popupRef.current.classList.remove(style.popup_visible);
            }}
        >
            <div className={style.popupHeadStatus}>
                <div id="title_adcl24" className={style.popupTitle}>
                    Статус:
                </div>
                <div id="status_adcl24" className={style.popupStatus}>
                    OK
                </div>
            </div>
            <div className={style.popupBodyMessage}>
                <div id="message_adcl24" className={style.popupMessage}></div>
            </div>
        </div>
    );
};
export default Popup;
