/* 
TODO: 20.08.23 23:23

*****************МАХИНАЦИИ С МЕДИА**************************
        Конвертер медиа
        
        Рамка текст, видео при создании записи
        Не заменять текущие медиафайлы при добавлении нового в редактировании поста
        тесты загрузки медиа с других сайтов
        анимация загрузки для медиа

        ---Скрипт для загрузки медиафайлов по ссылкам с других сайтов, заменить на свои
************************************************************


--- полный перенос сайта news на другой домен
---Кнопка класс на треш-сайте
---Последовательность рекламных блоков на clubok24.ru
---Кнопка копирования записи
---Сделать адекватную транслитерацию заголовка
---Навигация на страницу с записями/рекламы, с сохранёнными предыдущими параметрами сортировки
---быстрый просмотр записи (переделать)
---оповещения в меню на кнопках - коментарии, сообщения
---баг: при сохранении записи сортировка всех записей происходит некорректно, раздел записи поменять невозможно
---Статистика по сайту (вкратце)
---Изменить на сервере параметры period.from/to на period_from/period_to
---Переделать движок админки

*/

import { ContentProvider } from "./ContentProvider";
import Menu from "./components/Menu";
import style from "./css/Pages.module.css";
import { DEFAULT_USER_DATA } from "./data/DEFAULT_DATA";
import Comments from "./routes/Comments";
import CreateProvider from "./routes/Creator/CreateProvider";
import EntryPanel from "./routes/EntryPanel";
import Messages from "./routes/Messages";
import RecordsProvider from "./routes/Records/RecordsProvider";

import { useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Cookies from "universal-cookie";

// Вход в аккаунт, определение маршрутов
export default function App(props) {
    const { ref, width } = useResizeDetector();
    const maxWidth = 640;

    const cookies = new Cookies();
    const login = cookies.get("loginAdminCl24");
    const password = cookies.get("passwordAdminCl24");
    let obj = {
        id: "1",
        login: login,
        password: password,
        permissions: ["analyze"],
        roles: ["admin"],
        entry: "off",
    };

    if (login && password) {
        obj.login = login;
        obj.password = password;
        obj.entry = "on";
    }

    const [user, setUser] = useState(obj);
    const CONDITION = !(!!user && user.entry === "on");
    const handleLogout = () => {
        cookies.remove("loginAdminCl24");
        cookies.remove("passwordAdminCl24");
        cookies.remove("lastdatetime");

        setUser(obj);
    };
    const handleLogin = (errors, login, password) => {
        if (errors === 0) {
            obj.login = login;
            obj.password = password;
            obj.entry = "on";
            setUser(obj);
        }
    };

const entry = function () {
        obj = DEFAULT_USER_DATA;
        return CONDITION ? <EntryPanel handleLogin={handleLogin} cookies={cookies} /> : <></>;
    };

    if (!login || !password) return <EntryPanel handleLogin={handleLogin} cookies={cookies} />;
    return (
        <BrowserRouter>
            <ContentProvider>
                <div className={style.reload_page}></div>
                <div id="content_clubok24" ref={ref}>
                    <Menu width={width} maxWidth={maxWidth} handleLogout={handleLogout} />

                    <div className={style.mainWrapper}>
                        <div className={style.leftColumnWrapper}></div>
                        <div className={style.centralColumn}>
                            <Routes>
                                <Route path="/entry/*" element={entry()} />
                                <Route path="/messages" element={<Messages />} />
                                <Route path="/comments" element={<Comments />} />
                                <Route path="/createnews" element={<CreateProvider />}>
                                    <Route path=":edit_id" element={<CreateProvider />} />
                                </Route>
                                <Route path="/createadv" element={<CreateProvider />}>
                                    <Route path=":edit_id" element={<CreateProvider />} />
                                </Route>
                                <Route path="/" element={<CreateProvider />} />
                                <Route path=":path/*" element={<RecordsProvider />}>
                                    <Route path=":page/*" element={<RecordsProvider />} />
                                </Route>
                            </Routes>
                        </div>
                        <div className={style.rightColumnWrapper}></div>
                    </div>
                </div>
            </ContentProvider>
        </BrowserRouter>
    );
}
