import React from "react";
import styles from "./Input.module.css";

export default function InputCheckbox({ value, onChange, id, insc, name }) {
    return (
        <div className={styles.state_radio}>
            <div className={styles.insc}>{insc}</div>
            <div className={styles.form_toggle}>
                <div className={`${styles.form_toggle_item} ${styles.item_1}`}>
                    <input
                        id={`fid-1${id}`}
                        name={name}
                        type="radio"
                        value={0}
                        checked={Number(value) === 0}
                        onChange={onChange}
                    />
                    <label htmlFor={`fid-1${id}`}>Нет</label>
                </div>
                <div className={`${styles.form_toggle_item} ${styles.item_2}`}>
                    <input
                        id={`fid-2${id}`}
                        name={name}
                        type="radio"
                        value={1}
                        checked={Number(value) === 1}
                        onChange={onChange}
                    />
                    <label htmlFor={`fid-2${id}`}>Да</label>
                </div>
            </div>
        </div>
    );
}
