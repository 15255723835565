import style from "./Sorting.module.css";
import Calendar from "react-calendar";
import { getDate } from "../../functions/DateTime";
import $ from "jquery";
import React, { useRef, useState } from "react";
import { useRecords } from "../../routes/Records/RecordsProvider";

export default function CustomCalendar({ filter_period }) {
    const { params, methods } = useRecords();
    const { change_search_params } = methods;

    const { ObjSearchParams } = params;
    let { period_from = false, period_to = false } = ObjSearchParams || {};

    const new_period_from = new Date(period_from ? Number(period_from) : period_from),
        new_period_to = new Date(period_to ? Number(period_to) : period_to);

    const [activeMacross, setActiveMacross] = useState(null);

    const refMacross = useRef();
    refMacross.current?.addEventListener("wheel", onWheelMacross);

    function onClickButtonMove(e) {
        const datamove = e.target.getAttribute("data-move");
        const offset = refMacross.current?.getBoundingClientRect()?.width * datamove;
        $("#macross_period").animate({ scrollLeft: `+=${offset}` }, 500);
    }

    function onWheelMacross(e) {
        const macrossElement = refMacross.current;
        const toLeft = e.deltaY < 0 && macrossElement?.scrollLeft > 0;
        const toRight =
            e.deltaY > 0 &&
            macrossElement?.scrollLeft < macrossElement?.scrollWidth - macrossElement?.clientWidth;
        if (toLeft || toRight) {
            e.preventDefault();
            macrossElement.scrollLeft += e.deltaY;
        }
    }

    function onChangeCalendar(date, name) {
        date = date.getTime();
        let from, to;
        if (name === "from") {
            from = date;
            to = date > Number(period_to) ? date : Number(period_to);
        }
        if (name === "to") {
            to = date;
            from = date < Number(period_from) ? date : Number(period_from);
        }

        change_search_params({
            arrayValues: [
                {
                    key: "period_from",
                    value: from,
                },
                {
                    key: "period_to",
                    value: to,
                },
            ],
        });
    }

    function onClickMacross(e) {
        const name = e.target.getAttribute("name");
        setActiveMacross(e.target.dataset.index);

        if (!name) return;
        let from = new Date(),
            to = new Date();

        switch (name) {
            case "yesterday":
                const nextdate = from.getDate() - 1;
                to.setDate(nextdate);
                from.setDate(nextdate);
                break;
            case "week":
                from.setDate(from.getDate() - 7);
                break;
            case "month":
                from.setMonth(from.getMonth() - 1);
                break;
            case "quarter":
                from.setMonth(from.getMonth() - 3);
                break;
            case "today":
            default:
                break;
        }
        change_search_params({
            arrayValues: [
                {
                    key: "period_from",
                    value: from.getTime(),
                },
                {
                    key: "period_to",
                    value: to.getTime(),
                },
            ],
        });
    }
    const isActiveMacross = (index) => (index == activeMacross ? style.active : "");

    return (
        <div className={style.calendar_wrapper} title="">
            <div className={style.about_title}>
                {filter_period === "statistics" ? "Охват" : "Записи"} за период &#128198;
            </div>
            <div className={style.wrapperSetterDay}>
                <div
                    data-move={1}
                    onClick={onClickButtonMove}
                    className={`${style.prevent} ${style.arrow}`}
                />
                <div id="macross_period" ref={refMacross} className={style.setterDay}>
                    <div name="today" data-index={0} className={isActiveMacross(0)} onClick={onClickMacross}>
                        Сегодня
                    </div>
                    <div
                        name="yesterday"
                        data-index={1}
                        className={isActiveMacross(1)}
                        onClick={onClickMacross}
                    >
                        Вчера
                    </div>
                    <div name="week" data-index={2} className={isActiveMacross(2)} onClick={onClickMacross}>
                        Неделя
                    </div>
                    <div name="month" data-index={3} className={isActiveMacross(3)} onClick={onClickMacross}>
                        Месяц
                    </div>
                    <div
                        name="quarter"
                        data-index={4}
                        className={isActiveMacross(4)}
                        onClick={onClickMacross}
                    >
                        Квартал
                    </div>
                </div>
                <div data-move={-1} onClick={onClickButtonMove} className={`${style.next} ${style.arrow}`} />
            </div>
            <div className={style.calendars}>
                <div className={style.customCalendar}>
                    <div title="Период с ..." name="from" className={style.calendarTitle}>
                        С {getDate(new_period_from, "date")}
                    </div>
                    <Calendar
                        value={new_period_from}
                        onChange={(date) => onChangeCalendar(date, "from")}
                        className={style.calendar}
                    />
                </div>
                <div className={style.customCalendar}>
                    <div title="Период по ..." name="to" className={style.calendarTitle}>
                        По {getDate(new_period_to, "date")}
                    </div>

                    <Calendar
                        value={new_period_to}
                        onChange={(date) => onChangeCalendar(date, "to")}
                        className={style.calendar}
                    />
                </div>
            </div>
        </div>
    );
}
