import { useRecords } from "../../routes/Records/RecordsProvider";
import styles from "./Filter/Filter.module.scss";
import stylesSorting from "./Sorting.module.css";

export default function Grouping({ onChange, isNews }) {
    const { params, methods } = useRecords();
    const { ObjSearchParams } = params;
    const { to, value } = ObjSearchParams;
    const { change_search_params } = methods;

    function onClickButtonTo(e) {
        const name = e.target.name;
        change_search_params({ key: "to", value: name });
    }

    return (
        <div className={stylesSorting.child_range}>
            <select
                onChange={(e) => typeof onChange === "function" && onChange(e, "value")}
                className={styles.selector}
                title="Сортировать по параметру"
                value={value}
            >
                <option value="timestamp">Дата создания</option>
                <option value="title">Заголовок</option>
                {!isNews && <option value="view">Клики</option>}
                {isNews && (
                    <>
                        <option value="score">Рейтинг</option>
                        <option value="popularity">Оценки</option>
                        <option value="real_view">Реальные просмотры</option>
                        <option value="view">Все просмотры</option>
                    </>
                )}
            </select>
            <button
                title="По возрастанию"
                name="down"
                onClick={onClickButtonTo}
                className={to === "down" ? stylesSorting.buttonsUpDown : ""}
                disabled={to === "down"}
            >
                &#8657;
            </button>
            <button
                title="По убыванию"
                name="up"
                onClick={onClickButtonTo}
                className={to === "up" ? stylesSorting.buttonsUpDown : ""}
                disabled={to === "up"}
            >
                &#8659;
            </button>
        </div>
    );
}
