import $ from "jquery";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import stylesPagination from "../css/Pagination.module.css";
import style from "../css/styleTableAdv.module.css";
import { useRecords } from "../routes/Records/RecordsProvider";
import Item from "./Item";

const Pagination = () => {
    const { content, params } = useRecords();
    const { items } = content;
    const lenghtWithoutLimit = parseInt(params.lengthWithoutLimit || "0", 10);
    /* const lengthResponse = parseInt(params.lengthResponse || "0", 10); */
    const searchParams = $.param(params.ObjSearchParams);

    let { path, page = 1 } = useParams();
    page = Number(page);

    const navigate = useNavigate();

    const pages_length = Math.ceil(lenghtWithoutLimit / 21);
    const offset = 3;

    let isExistsNextDots = false,
        isExistsPrevDots = false;

    const itemsMap = (items) => items.map((element, index) => <Item key={index} element={element} />);

    function dotsOverflow(isOverflowNext = false, isOverflowPrev = false, i = 1) {
        if (isOverflowNext && !isExistsNextDots) {
            isExistsNextDots = true;
            return (
                <Link
                    key={i}
                    to={`/${path}/${i + offset - 3}?${searchParams}`}
                    data-target="next"
                    className={stylesPagination.page_item}
                >
                    ...
                </Link>
            );
        }
        if (isOverflowPrev && !isExistsPrevDots) {
            isExistsPrevDots = true;
            return (
                <Link
                    key={i}
                    to={`/${path}/${i - offset}?${searchParams}`}
                    data-target="prev"
                    className={stylesPagination.page_item}
                >
                    ...
                </Link>
            );
        }
    }
    function linksMap(pages_length) {
        if (pages_length < 2) return false;
        const links_array = [];
        for (let i = 1; i <= pages_length; i++) {
            const overflowlinksNext = i >= page + offset && page + offset < pages_length && i < pages_length;
            const overflowlinksPrev = i <= page - offset && page - offset > 0 && i > 1;

            links_array.push(
                !overflowlinksNext && !overflowlinksPrev ? (
                    <NavLink
                        key={i}
                        to={`/${path}/${i}?${searchParams}`}
                        className={({ isActive }) =>
                            isActive || (i == 1 && page == 1)
                                ? `${stylesPagination.page_item} ${stylesPagination.page_item_active}`
                                : stylesPagination.page_item
                        }
                    >
                        {i}
                    </NavLink>
                ) : (
                    dotsOverflow(overflowlinksNext, overflowlinksPrev, i)
                )
            );
        }
        return (
            <div className={stylesPagination.pagesWithButtons}>
                <div className={stylesPagination.ul_pagination}>
                    <button
                        onClick={() => {
                            onClickNavigate(-1);
                        }}
                        aria-disabled={page == 1}
                        className={stylesPagination.btn_Pagination}
                    >
                        &#9668;
                    </button>
                    {links_array}
                    <button
                        onClick={() => {
                            onClickNavigate(1);
                        }}
                        aria-disabled={page == pages_length}
                        className={stylesPagination.btn_Pagination}
                    >
                        &#9658;
                    </button>
                </div>
            </div>
        );
    }
    function onClickNavigate(to) {
        const pageNum = parseInt(page, 10);
        if (to < 1 ? pageNum > 1 : pageNum < pages_length) {
            navigate(`/${path}/${pageNum + to}?${searchParams.toString()}`);
        }
    }
    return (
        <div className={stylesPagination.tableWithPagination}>
            <div className={style.tableWithItems}>{itemsMap(items)}</div>
            {pages_length > 1 && linksMap(pages_length)}
        </div>
    );
};
export default Pagination;
