import { SyntheticEvent } from "react";
import stylesFilter from "../Sorting/Filter/Filter.module.scss";
import styles from "./Input.module.css";

interface ISelector {
    options: Array<string | { name: string }>;
    value: string;
    onChange: (e: SyntheticEvent<HTMLSelectElement>) => void;
    title: string;
    className?: string;
    name?: string;
}

const Selector = ({ options, value, onChange, title, name, className = "" }: ISelector) => {
    const optionElements = options.map((element, index) => {
        const optionName = typeof element !== "string" ? element.name : element;

        const engRus = optionName.split("=");
        return (
            <option key={index} value={optionName}>
                {engRus[1]}
            </option>
        );
    });
    return (
        <div className={className || "selector_club"}>
            {title && <div className={styles.insc}>{title || "Раздел"}</div>}
            <select name={name} onChange={onChange} className={stylesFilter.selector} value={value}>
                {optionElements}
            </select>
        </div>
    );
};
export default Selector;
