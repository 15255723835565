import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import style from "../css/styleTableAdv.module.css";
import { POSITIONS_ADV } from "../data/DEFAULT_DATA";
import URL_COLLECTION from "../data/URL";
import averageValueRating from "../functions/averageValuesArray";
import MediaElement from "../functions/MediaElement";
import { useRecords } from "../routes/Records/RecordsProvider";
import InputNote from "./Input/InputNote";
import Item_options from "./Options/Item_options";

const { media_items } = URL_COLLECTION;

export default function Item({ element }) {
    const { methods } = useRecords();
    const { options_item } = methods;

    const { path } = useParams();
    const [searchParams] = useSearchParams();
    const table = searchParams?.get("table") || path;

    //all props
    const { link, view, id, media, title } = element;
    //adv props
    const { adv_admin, checked, timestamp, position, insc_ads, partition, is_trash, note } = element;
    //news props
    const {
        desc_news,
        part,
        date_local,
        time_local,
        rating,
        keywords,
        desc_meta,
        isVideoFrame,
        part_active = 1,
        img_web,
        real_view,
        comments,
        site,
        subpart,
        subpart_active = 1,
    } = element;

    const isAdv = element.path === "adv";
    const isNews = element.path === "news";
    const isRecycle = path === "recycle";
    const partition_news_array = isNews ? part?.split("=") || "news" : false;
    const subpartition_news_array = isNews && subpart ? subpart?.split("=") || "news" : false;

    let href_to_record;

    switch (site) {
        case "super-story.ru":
            href_to_record = isNews ? `https://${site}/${encodeURIComponent(link)}` : link;
            break;
        case "video.clubok24.ru":
        case "clubok24.ru":
        default:
            if (!isNews) {
                href_to_record = link;
                break;
            }
            if (subpart)
                href_to_record = `https://${site}/category/${partition_news_array[0]?.toLowerCase()}/s/${subpartition_news_array[0]?.toLowerCase()}/${encodeURIComponent(
                    link
                )}`;
            else
                href_to_record = `https://${site}/category/${partition_news_array[0]?.toLowerCase()}/${encodeURIComponent(
                    link
                )}`;
            break;
    }

    const views_params = (
        <li className={style.views_itemInTable}>
            {isNews ? "просмотры — " : "клики — "}
            <span>
                {view}
                {isNews ? `(${real_view})` : ""}
            </span>
        </li>
    );
    const rating_params = isNews && (
        <li className={style.averageRating}>
            рейтинг —&nbsp;
            <span>
                {averageValueRating([...Object.values(rating || {})])}&nbsp;/&nbsp;
                {[...Object.values(rating || {})]?.length || 0}
            </span>
        </li>
    );
    const comments_params = isNews && (
        <li className={style.averageRating}>
            комментарии — <span>{comments}</span>
        </li>
    );
    const date_params = (
        <li className={style.date_itemIntable}>
            дата — {date_local && time_local ? `${date_local} ${time_local}` : timestamp}
        </li>
    );
    const [isHoverMeta, setIsHoverMeta] = useState(false);
    const [isShowShare, setIsShowShare] = useState(false);

    function keyword_table(keywords) {
        if (!keywords)
            return (
                <div className={style.keyword} style={{ borderRadius: 0 }}>
                    Отсутствуют
                </div>
            );
        const parseKeywords = keywords
            ?.replace(/\s{2,}/g, " ") /* no double space */
            ?.trim() /* no space start-end position */
            ?.split(" "); /* to the array  */
        const keywordsArray = [];
        parseKeywords.forEach((element, index) => {
            keywordsArray.push(
                <div key={index} className={style.keyword}>
                    {element}
                </div>
            );
        });
        return keywordsArray;
    }
    function toggleChecked(e) {
        const dataid = e.target.getAttribute("data-id");
        const datachecked = e.target.getAttribute("data-checked") === "1" ? false : true;
        options_item({ action: "toggle", recycle: { id: dataid, table: element.path, value: datachecked } });
    }

    function onClickCopy(e) {
        e.target.classList.remove(style.oncopy);

        const tlink = e.target.getAttribute("data-link");
        navigator.clipboard.writeText(tlink);

        e.target.classList.add(style.oncopy);
        setTimeout(() => {
            e.target.classList.remove(style.oncopy);
        }, 2000);
    }

    //share items
    let targetImage, imagepng;
    if (!!media) {
        let firstimg = media?.split(",")[0];
        targetImage = media_items + firstimg;
        imagepng = media_items + "dd/" + firstimg?.split(".")[0] + ".png";
    } else if (!!img_web) targetImage = img_web.split(",")[0];

    const textTitleLink = title?.replaceAll(" ", "");
    const hashtagsLink = keywords?.replaceAll(" ", ",")?.replaceAll("-", "");

    const share_items = [
        {
            name: "ok",
            title: "Одноклассники",
            link_share: `connect.ok.ru/offer?url=${href_to_record}&title=${title}&imageUrl=${
                imagepng || targetImage
            }&description=${desc_meta}`,
        },
        {
            name: "vk",
            title: "Вконтакте",
            link_share: `vk.com/share.php?url=${href_to_record}&title=${title}&image=${targetImage}&description=123&noparse=false`,
        },
        {
            name: "tg",
            title: "Телеграм",
            link_share: `t.me/share/url?url=${href_to_record}&text=${desc_meta}`,
        },
        {
            name: "wh",
            title: "WhatsApp",
            link_share: `api.whatsapp.com/send/?text=${desc_meta} ${href_to_record}`,
        },
        {
            name: "pin",
            title: "Pinterest",
            link_share: `ru.pinterest.com/pin/create/button/?url=${href_to_record}&media=${
                imagepng || targetImage
            }&description=${textTitleLink}`,
        },
        {
            name: "tw",
            title: "Twitter",
            link_share: `twitter.com/intent/tweet?url=${href_to_record}&text=${desc_meta?.substring(
                0,
                50
            )}...&hashtags=${hashtagsLink}`,
        },
    ];
    const memoizedShareItems = share_items.map((el, index) => {
        const { name, title, link_share } = el;

        return (
            <div
                key={index}
                title={title}
                className={`${style.share_icon} ${style[`${name}_icon`]}`}
                onClick={(e) => {
                    console.log(encodeURI(`https://${link_share}`));
                    window.open(
                        encodeURI(`https://${link_share}`),
                        "sharer",
                        "status=0,toolbar=0,width=650,height=500"
                    );
                }}
            >
                <button data-social={name} className={`${style.share_button} ${style[name]}`} />
            </div>
        );
    });
    const itemMediaParams = (
        <div className={style.mediaContainer_itemInTable}>
            <MediaElement
                url={media_items}
                videoparams={{ autoPlay: true, loop: true, muted: true }}
                imageparams={{
                    loading: "lazy",
                }}
                media={{
                    src: media,
                }}
                events={{
                    onMouseMove: function (e) {
                        e.target.nodeName === "VIDEO" && e.target.play();
                    },
                }}
                isOnlyOne={true}
                className={style.imageItem_forView}
            />
            {isVideoFrame === "1" && <div className={style.playButtonFrameVideoPlayer}></div>}
        </div>
    );
    const itemButtons = isNews && (
        <>
            <div
                title="Поделиться"
                style={{ right: isNews && isRecycle ? "0px" : "" }}
                onClick={() => setIsShowShare(!isShowShare)}
                className={style.sharebutton}
            >
                {isNews && isShowShare && <div className={style.share_wrapper}>{memoizedShareItems}</div>}
            </div>
            <div
                title="Описание и ключевые слова"
                style={{ right: isNews && isRecycle ? "0px" : "" }}
                onClick={() => setIsHoverMeta(!isHoverMeta)}
                className={style.metacontent}
            ></div>
        </>
    );
    const itemButtonsChecked = !isRecycle && (
        <>
            <div
                title={checked === "1" ? "Убрать в архив" : "Вернуть из архива"}
                className={style.filter}
                data-id={id}
                data-checked={checked}
                onClick={toggleChecked}
            />
        </>
    );
    const itemeMetaContent = (
        <>
            <div className={style.metaposition}>
                <div data-active={part_active && subpart_active} className={style.partition_itemInTable}>
                    {isAdv && position ? (
                        <>
                            <div className={style.insc_position}>
                                Позиции <div className={style.expand} />
                            </div>
                            <div className={style.positions}>
                                {position.split(",").map((el, index) => {
                                    const tpos =
                                        Array.isArray(POSITIONS_ADV) &&
                                        POSITIONS_ADV.findIndex((eli) => eli.includes(el));
                                    return <div key={index}>{POSITIONS_ADV[tpos].split("=")[1] || el}</div>;
                                })}
                            </div>
                        </>
                    ) : (
                        <div className={style.insc_position}>
                            {part && partition_news_array[1]?.toLowerCase()}
                            {subpart && `_${subpartition_news_array[1]?.toLowerCase()}`}
                        </div>
                    )}
                </div>
                {isAdv && insc_ads === "1" ? <div className={style.insc_ads}>РЕКЛАМА</div> : ""}
                {isAdv && is_trash === "0" ? <div className={style.insc_ads}>Основной</div> : ""}
                {isAdv && is_trash === "1" ? <div className={style.insc_ads}>Треш</div> : ""}
                {isAdv && is_trash === "2" ? <div className={style.insc_ads}>Осн./Треш</div> : ""}
            </div>
            {isNews && isHoverMeta && (
                <div className={style.meta_wrapper}>
                    <h3>Описание в поиске: </h3>
                    <div>{desc_meta}</div>
                    <h3>Ключевые слова:</h3>
                    <div>{keyword_table(keywords)}</div>
                </div>
            )}
        </>
    );
    const itemDescription = (
        <div className={style.bottomBlock_itemInTable}>
            <ul className={style.about_itemInTable}>
                {views_params}
                {comments_params}
                {rating_params}
                {date_params}
            </ul>
            <div className={style.title_itemInTable}>{title}</div>
            <div className={style.description_itemInTable}>{desc_news}</div>
        </div>
    );

    return (
        <div data-table={table} id={`${id}_mediadata`} className={style.itemInTable}>
            <div className={style.id_targetPost_itemInTable}>#{id}</div>
            <div className={style.topBlock_itemInTable}>
                {itemMediaParams}
                <div className={style.itemButtons}>
                    {itemButtonsChecked}
                    <div
                        title="Скопировать ссылку"
                        className={style.copy_link}
                        data-link={href_to_record}
                        onClick={onClickCopy}
                    />
                    {!isRecycle && (
                        <a
                            title="Перейти к записи на сайте"
                            className={style.goToLink_itemInTable}
                            href={href_to_record}
                            target="_blank"
                            rel="noopener noreferrer"
                        ></a>
                    )}
                    {itemButtons}
                </div>
                {isNews && (
                    <a href={`https://${site}`} target="_blank" className={style.siteName}>
                        {site}
                    </a>
                )}
                {isAdv && <div className={style.siteName}>{partition?.split("=")[1]}</div>}
                {itemeMetaContent}
            </div>
            <Item_options id={id} site={site} table={element.path} />
            {itemDescription}
            {isAdv && <InputNote value={note} id={id} table={table} column="note" />}
        </div>
    );
}
