import EmojiPicker from "emoji-picker-react";
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import styles from "./Input.module.css";

interface IInputText {
    tag?: "textarea" | "input";
    value: string;
    max?: number;
    name: string;
    type?: "text" | "radio" | "number" | "date" | "time";
    input_plc: string;
    input_insc: string;
    required_class?: string;
    autofocus?: boolean;
    required?: boolean;
    errorMessage?: boolean;
    isInsertButtons?: boolean;
    disabled?: boolean;
    autocomplete?: "on" | "off";
    emoji?: boolean;
    city?: boolean;
    date?: boolean;
    style?: CSSProperties;
    styleInput?: CSSProperties;
    onChange: (value: string) => void;
}

const InputText = ({
    tag = "textarea",
    value,
    onChange,
    max,
    name = "input_text",
    type = "text",
    input_plc = "Текст..",
    input_insc = "",
    required_class = "noreq",
    autofocus = false,
    required = false,
    errorMessage = false,
    isInsertButtons = false,
    disabled = false,
    autocomplete = "on",
    emoji = false,
    city = false,
    date = false,
    style = {},
    styleInput = {},
}: IInputText) => {
    const [visibleEmoji, setVisibleEmoji] = useState(false);
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

    useEffect(() => {
        if (inputRef.current) {
            if (!value) inputRef.current.style.height = "50px";
            else {
                inputRef.current.style.height = "auto";
                inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
            }
        }
    }, [value]);

    function limit() {
        const valueNoUndefined = value ? value?.length : "";
        if (max && value) {
            return (
                <div className={styles.max_lenght}>
                    {valueNoUndefined}/{max}
                </div>
            );
        }
    }
    function insert(target: HTMLInputElement | HTMLTextAreaElement, string: string) {
        const selstart = target.selectionStart;
        if (typeof selstart !== "number") return;

        const newValue =
            typeof value === "string"
                ? [value.slice(0, selstart), string, value.slice(selstart)].join("")
                : "";
        onChange(newValue);
    }
    const onEmojiButton = ({ isToggle = false, state = false }) =>
        setVisibleEmoji(isToggle ? state : !visibleEmoji);
    const cityButton = (
        <div className={styles.wrapper_target_link}>
            <div
                onClick={() => inputRef.current && insert(inputRef.current, "{city}")}
                title="Вставить закладку для города"
                className={styles.target_link_city}
            />
        </div>
    );
    const dateButton = (
        <div className={styles.wrapper_target_link}>
            <div
                onClick={() => inputRef.current && insert(inputRef.current, "{date}")}
                title="Вставить закладку для даты"
                className={styles.target_link_date}
            />
        </div>
    );
    const memoEmojiPicker = useMemo(() => {
        return (
            <EmojiPicker
                height={600}
                width={400}
                onEmojiClick={({ emoji }) => inputRef.current && insert(inputRef.current, emoji)}
                lazyLoadEmojis
                //@ts-ignore
                emojiStyle="google"
            />
        );
    }, [value]);
    const emojiButton = (
        <div
            role="button"
            tabIndex={2}
            onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget))
                    onEmojiButton({ isToggle: true, state: false });
            }}
            className={styles.wrapper_target_link}
        >
            <div
                style={{ transform: visibleEmoji ? "rotate(-90deg)" : "" }}
                //@ts-ignore
                onClick={onEmojiButton}
                title="Вставить эмодзи"
                className={styles.target_link_emoji}
            />
            {visibleEmoji ? <div className={styles.emojiPicker}>{memoEmojiPicker}</div> : ""}
        </div>
    );
    const attributes: any = {
        disabled,
        name,
        ref: inputRef,
        maxLength: max,
        className: `${styles.fortext} ${required_class}`,
        autoFocus: autofocus,
        value,
        required,
        type,
        placeholder: input_plc,
        style: { resize: "none", overflow: "hidden", ...styleInput },
        autoComplete: autocomplete,
        onChange,
    };
    return (
        <div style={style} className={styles.input_container}>
            <div className={styles.insc}>
                <div className={styles.title}>
                    {input_insc}
                    {required ? "*" : ""}
                </div>
                {isInsertButtons && (
                    <div className={styles.buttons_wrapper}>
                        {city && cityButton}
                        {date && dateButton}
                        {emoji && emojiButton}
                    </div>
                )}
                {errorMessage && <span className={styles.error_msg}>{errorMessage}</span>}
            </div>

            {tag === "textarea" ? <textarea {...attributes} /> : <input {...attributes} />}
            {limit()}
        </div>
    );
};
export default React.memo(InputText);
