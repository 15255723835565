import React, { useState } from "react";
import style from "./Input.module.css";

const includesArray = (values, options, i) => {
    return values.map((el) => {
        const index = options.findIndex((elindex) => elindex.includes(el));
        if (index !== -1) return options[index].split("=")[i];
    });
};

function MultipleCheckbox({ id, values, onChange, options, title = false, name }) {
    const [expand, setExpand] = useState(false);

    if (!Array.isArray(options) || !Array.isArray(values)) return;

    const firstVal = options[0].split("=")[0];
    const dataValues = {
        texts: includesArray(values, options, 1),
        values: includesArray(values, options, 0),
    };

    function onClickOption(e) {
        const tval = e.target.getAttribute("data-value");
        let newValues = [];
        if (dataValues.values.includes(tval)) {
            newValues = [...dataValues.values];
            newValues = newValues.filter((el) => el !== tval);

            if (dataValues.values.length - 1 === 0) newValues = [firstVal];
        } else {
            newValues = [...dataValues.values, tval];
            if (tval !== firstVal) newValues = newValues.filter((el) => el !== firstVal);
            if (tval === firstVal) newValues = [firstVal];
        }
        typeof onChange === "function" && onChange(newValues);
    }
    const onExpandList = (_) => setExpand((prevstate) => !prevstate);

    const customOptions = options.map((el, index) => {
        const elArray = el.split("=");
        const isChecked = dataValues.values.includes(elArray[0]);
        return (
            <div
                key={index}
                name={name}
                data-value={elArray[0]}
                onClick={onClickOption}
                className={style.multiple_selector_custom_option}
            >
                {elArray[1]}
                {isChecked && <div className={style.checkmark} />}
            </div>
        );
    });
    const selectedValues = dataValues.texts.map((el, index) => (
        <div data-id={`${id}_${index}`} key={index} className={style.selected_option}>
            {el} <div className={style.remove} />
        </div>
    ));
    return (
        <div tabIndex={0} onBlur={onExpandList} className={style.multiple_selector_block}>
            <div className={style.insc}>{title || "Раздел"}</div>
            <div className={style.multiple_selector_container}>
                <div onClick={onExpandList} className={style.multiple_selector_map}>
                    {selectedValues}
                </div>
                <div className={`${style.multiple_selector} ${expand ? style.active : ""}`}>
                    {customOptions}
                </div>
            </div>
        </div>
    );
}

export default MultipleCheckbox;
