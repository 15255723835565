import $ from "jquery";
import React, { useState } from "react";
import styles from "../css/EntryPanel.module.css";

const EntryPanel = (props) => {
    const { handleLogin, cookies } = props;
    //TODO: Перенести в бд
    const login = "bondnadejda"; //@ya.ru => req
    const password = "R2d2C3poLtD102"; // lenght 14 => req

    const [check, setCheck] = useState(true);
    const ref = React.createRef();
    const [value, setValue] = useState({
        login: "",
        password: "",
    });

    const onChahgeInput = (event) => {
        const name = event.target.name;
        const eVal = event.target.value;
        setValue({
            ...value,
            [name]: eVal,
        });
    };

    const onClickEntry = () => {
        let errors = 0;
        const isError = () => {
            const error_message = document.getElementById("error_login");
            error_message.style.display = "flex";
            error_message.focus();
        };
        if (value.password.length < 14) {
            isError();
            errors++;
        } else if (value.password !== password) {
            isError();
            errors++;
        }
        if (!value.login) {
            isError();
            errors++;
        } else if (value.login !== login) {
            isError();
            errors++;
        }
        if (errors === 0) {
            if (check) {
                const lastDate = new Date(Date.now()).toUTCString();
                cookies.set(`loginAdminCl24`, encodeURIComponent(login), {
                    maxAge: "25920000",
                    sameSite: "strict",
                    secure: true,
                });
                cookies.set(`passwordAdminCl24`, encodeURIComponent(password), {
                    maxAge: "25920000",
                    sameSite: "strict",
                    secure: true,
                });
                cookies.set(`lastdatetime`, encodeURIComponent(lastDate), {
                    maxAge: "25920000",
                    sameSite: "strict",
                    secure: true,
                });
                window.location.reload();
            }
            handleLogin(errors, login, password);
        }
    };
    const onClickCheckBox = (event) => {
        let b;
        if (event.target.checked) {
            b = 1;
        } else {
            b = 0;
        }
        setCheck(b);
    };
    //не вставлять данные форм в адресную строку
    $(".login-form").submit(function (e) {
        e.preventDefault();
    });
    return (
        <div className={styles.fullEntryBlock}>
            <div className={styles.entry_block_area}>
                <div className={styles.entry_block}>
                    <form className="login-form" autoComplete="on" noValidate>
                        <h2>
                            Панель администратора <br />
                            сайта Clubok24.ru
                        </h2>
                        <div className={styles.entry_menu}>
                            <div className={styles.entry_login}>
                                <div className={styles.title}>Логин</div>
                                <input
                                    name="login"
                                    value={value.login}
                                    onChange={(event) => onChahgeInput(event)}
                                    type="text"
                                    placeholder="my_login"
                                    className={styles.controlInput}
                                ></input>
                            </div>
                            <div className={styles.entry_password}>
                                <div className={styles.title}>Пароль</div>
                                <input
                                    name="password"
                                    value={value.password}
                                    onChange={(event) => onChahgeInput(event)}
                                    type="password"
                                    placeholder="my_password"
                                    className={styles.controlInput}
                                ></input>
                            </div>

                            <div className={styles.entry_checkbox}>
                                <div id={styles.checkbox_title} className={styles.title}>
                                    Запомнить меня &rArr;
                                </div>
                                <input
                                    ref={ref}
                                    onChange={onClickCheckBox}
                                    checked={check}
                                    type="checkbox"
                                    id={styles.highload1}
                                    name="highload1"
                                ></input>
                                <label
                                    htmlFor={styles.highload1}
                                    data-onlabel="Да"
                                    data-offlabel="Нет"
                                    className={styles.lb1}
                                ></label>
                            </div>
                        </div>
                        <div className={styles.log_in}>
                            <div id="error_login" className={styles.error_message}>
                                Логин или пароль <br /> не верны
                            </div>
                            <button type="submit" onClick={onClickEntry} className={styles.loginbutton}>
                                Войти
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default EntryPanel;
