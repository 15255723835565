import { useLocation } from "react-router-dom";
import styles from "./Options.module.css";
import { useCreate } from "../../routes/Creator/CreateProvider";

const Create_options = (props) => {
    let { pathname } = useLocation();
    pathname = pathname?.split("/")[1];

    const { creator } = useCreate();
    const { clearData, formValidate } = creator;

    const reset = () => clearData();
    const submit = () => formValidate(pathname);

    return (
        <div className={styles.buttons_create}>
            <div className={styles.container_clear_send}>
                <a
                    role="button"
                    tabIndex={0}
                    name="clear"
                    className={styles.clear}
                    type="reset"
                    onClick={reset}
                    title="Очистить поля"
                >
                    <i aria-hidden={true}></i>
                </a>
                <a
                    role="button"
                    tabIndex={0}
                    name="send"
                    className={styles.send}
                    id="send_button_clubok"
                    type="submit"
                    onClick={submit}
                    title="Сохранить пост"
                >
                    <i aria-hidden={true}></i>
                </a>
            </div>
        </div>
    );
};
export default Create_options;
