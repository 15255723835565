import styles from "./Input.module.css";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import $ from "jquery";
import React from "react";

const EditorTextArea = ({ name, value, onChange, max, ins, placeholder, required_class }) => {
    /* value = value.replace(/<\/?span data-mce-style="*"[^>]*>/g, ""); */
    /* value = value ? value.replace(/\&nbsp\;/gi, " ") : value; */
    let timer;

    const editorRef = useRef(null);
    function limitLenghtCharsOnEditor() {
        const valueNoUndefined = value ? value.length : 0;
        if (max !== undefined) {
            return (
                <div id="maxCharsTextArea" className={styles.max_lenght}>
                    {valueNoUndefined} из {max} доступных символов
                </div>
            );
        }
    }
    function onChangeText(htmlText) {
        const length = htmlText.length;
        const el_max = document.getElementById("maxCharsTextArea");
        if (length <= max) {
            onChange(htmlText);
            el_max.classList.remove(styles.err_max_chars);
        } else el_max.classList.add(styles.err_max_chars);
    }
    function handleBeforeAddUndo(evt, editor) {
        const length = editor.getContent({ format: "html" }).length;
        if (length > max) evt.preventDefault();
    }
    $(function () {
        timer = setInterval(() => {
            const p = $("#container_editor");
            const a = $(".tox-tinymce-aux");
            a.prependTo(p);
            if ($(document.body).children(".tox-tinymce-aux").length === 0) clearInterval(timer);
        }, 3000);
    });

    return (
        <div id="container_editor" className={`${styles.create_menu_textarea} ${required_class}`} name={name}>
            <div className={styles.insc}>{ins}</div>
            <div id="editorContainer" className={styles.editor_container}>
                <Editor
                    id="editor_textarea"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    apiKey="f7wreco7lvsmw03rv4g9lrd7nbvvyllxm8zbvjahsdn0qjkj"
                    value={value}
                    onEditorChange={onChangeText}
                    onBeforeAddUndo={handleBeforeAddUndo}
                    init={{
                        selector: "textarea",
                        placeholder: placeholder,
                        min_height: 600,
                        max_width: 100,
                        language: "ru",
                        menubar: true,
                        browser_spellcheck: true,
                        autosave_interval: "1s",
                        toolbar_sticky: true,
                        toolbar_sticky_offset: 0,
                        contextmenu: "copy paste selectall link",
                        file_picker_types: "image media",
                        pagebreak_separator: '<span class="separator_page_club"></span>',
                        font_size_formats: "14pt 15pt 16pt 18pt 24pt 36pt 48pt 52pt",
                        pagebreak_split_block: true,
                        plugins:
                            "table code autolink link autosave autoresize emoticons insertdatetime preview table wordcount anchor charmap" +
                            " lists searchreplace help image media pagebreak",
                        toolbar1:
                            "bold italic underline strikethrough hr subscript superscript blockquote forecolor backcolor formatpainter" +
                            " numlist bullist alignleft aligncenter alignright alignjustify lineheight outdent indent " +
                            "| fontsize fontfamily formatselect blocks removeformat cut paste selectall  remove| undo redo preview",
                        toolbar2:
                            " |emoticons charmap table  anchor  insertdatetime link" +
                            " image media | code print restoredraft searchreplace help  pagebreak",
                        setup(ed) {
                            ed.on("init", function (e) {
                                window.tinyMCE.execCommand("fontSize", false, "16pt");
                                window.scrollTo(0, 0);
                            });
                        },
                    }}
                />
            </div>
            {limitLenghtCharsOnEditor()}
        </div>
    );
};

export default React.memo(EditorTextArea);
