import { SyntheticEvent } from "react";
import styles from "./Input.module.css";

interface IThrippleSelector {
    id: string | number;
    options: Array<string>;
    value: string;
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    title?: string;
    insc: string;
    className?: string;
    name?: string;
}

export default function TrippleCheckbox({
    value,
    onChange,
    id,
    options = [],
    insc,
    name,
}: IThrippleSelector) {
    const mapNameSpace = options.map((element, index) => {
        return (
            <div key={index} className={styles.form_toggle_item}>
                <input
                    id={`fid-${index}${id}`}
                    type="radio"
                    name={name}
                    value={index}
                    checked={Number(value) === index}
                    onChange={onChange}
                />
                <label title={element} htmlFor={`fid-${index}${id}`}>
                    {element}
                </label>
            </div>
        );
    });

    return (
        <div className={styles.state_radio}>
            <div className={styles.insc}>{insc}</div>
            <div className={styles.form_toggle}>{mapNameSpace}</div>
        </div>
    );
}
