import axios from "axios";
import $ from "jquery";
import { createContext, useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useContent } from "../../ContentProvider";
import Viewer from "../../components/Viewer/Viewer";
import style from "../../css/Pages.module.css";
import { DEFAULT_PARAMS_SORTING } from "../../data/DEFAULT_DATA";
import URL_COLLECTION from "../../data/URL";
import Records from "./Records";

const RecordsContext = createContext(null);
export const useRecords = () => useContext(RecordsContext);

const defaultParamsSortingString = new URLSearchParams(DEFAULT_PARAMS_SORTING).toString();
const { get_records, options } = URL_COLLECTION.scripts.main;

export default function RecordsProvider() {
    const [searchParams, setSearchParams] = useSearchParams();
    const ObjSearchParams = searchParams.size === 0 ? false : Object.fromEntries(searchParams);
    const { search } = ObjSearchParams;

    const { path, page = 1 } = useParams();
    const { store } = useContent();
    const { message, reload, blackout, requestMainData } = store;

    // all items (by pathname)
    const [items, setItems] = useState([]);
    const [paramsItems, setParamsItems] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    function clearing_items() {
        setItems([]);
        setIsLoading(true);
    }
    function loading_items({ table = path, nextpage = page } = {}) {
        reload(1);

        const newUrlParams = new URLSearchParams({
            ...DEFAULT_PARAMS_SORTING,
            ...ObjSearchParams,
            page: nextpage,
            action: 0,
            ...(table !== "recycle" && {
                table,
                action: 1,
            }),
        });

        const url_get = new URL(`${get_records}?${newUrlParams.toString()}`);
        axios
            .get(url_get)
            .then((response) => {
                const { items, params } = response.data;
                const responseArray = [...Object.values(items)];
                setParamsItems(params);
                setItems(responseArray);
            })
            .catch(() => message("Ошибка загрузки записей", "ERRRQ", "Ошибка:", 1))
            .finally(() => {
                reload(0);
                setIsLoading(false);
            });
    }
    function options_item({ action, copy = {}, recycle = {} }) {
        function _send_options(params) {
            axios
                .get(`${options}?${$.param(params)}`)
                .then(({ data }) => {
                    data = data || "Действие выполнено успешно.";
                    message(data, "OK", "Статус:", -1);
                })
                .catch(() => message("Ошибка  выполнения действия", "ERRRQ", "Ошибка:", 1))
                .finally(loading_items);
        }
        let params = {};

        //check option
        if (typeof action !== "string" && typeof action !== "number") return;
        action = action + "";

        switch (action) {
            case "copy":
                if (typeof copy === "object" && !!JSON.stringify(copy)) {
                    const { id, prevsite, nextsite } = copy;
                    if (!id || !prevsite || !nextsite) return false;
                    params = { id, prevsite, nextsite };
                } else return false;
                break;
            case "toggle":
            case "0":
            case "1":
            case "-1":
            default:
                if (typeof recycle === "object" && !!JSON.stringify(recycle)) {
                    const { id, table, value } = recycle;
                    const isVal =
                        typeof value !== "boolean" && typeof value !== "string" && typeof value !== "number";
                    const cond = !id || !table || isVal;

                    if (cond) return false;
                    params = { id, table, value };
                } else return false;
                break;
        }
        params.action = action;

        _send_options(params);
        return true;
    }

    function change_search_params({ arrayValues = false, parent_name, value, event, key, clear = false }) {
        if (clear) return setSearchParams(DEFAULT_PARAMS_SORTING);

        let tVal = value;
        if (event?.target || event?.currentTarget) tVal = event.target.value || value;
        const newParams = structuredClone(ObjSearchParams);
        newParams[key] = tVal;

        if (arrayValues && Array.isArray(arrayValues)) {
            arrayValues.forEach((element) => {
                const { key, value } = element;
                if (!key || !value) return;
                newParams[key] = value;
            });
        }

        if (key === "site") {
            requestMainData({ data: { site: value } });
            newParams["partition"] = "all";
        }
        if (key === "partition") newParams["subpartition"] = "all";
        if (key === "subpartition" && parent_name) newParams["partition"] = parent_name;

        setSearchParams(newParams);
    }

    // viewer item (onclick button-view)
    const [viewRecord, setViewRecord] = useState(false);
    function view_item({ id = "", table = "", site }) {
        if (typeof viewRecord === "object" && viewRecord.id === id && viewRecord.path === table) {
            return console.log("current");
        }

        blackout(style.reload_page, 0, 1, "visible");
        axios
            .get(`${URL_COLLECTION.scripts.main.get_item_editview}?id=${id}&table=${table}`)
            .then((response) => setViewRecord(Object.values(response?.data || {})[0]))
            .then(() => blackout(style.reload_page, 0, 0, "hidden"));
    }
    const close_item = () => setViewRecord(false);

    // setting parameters for the browser url string if the url-search has no arguments
    useEffect(() => {
        !searchParams.size && setSearchParams(DEFAULT_PARAMS_SORTING);
        clearing_items();
        loading_items();
    }, [window.location.href]);

    const params = { search, path, ObjSearchParams, ...paramsItems };
    const content = { items, viewRecord, isLoading };
    const methods = { options_item, loading_items, change_search_params, view_item, close_item };

    return (
        <RecordsContext.Provider value={{ params, content, methods }}>
            {viewRecord && <Viewer />}
            <Records />
        </RecordsContext.Provider>
    );
}
