import axios from "axios";
import $ from "jquery";
import { createContext, useContext, useEffect, useState } from "react";
import Popup from "./components/Popup";
import style from "./css/Pages.module.css";
import { DEFAULT_OTHER_DATA } from "./data/DEFAULT_DATA";
import URL_COLLECTION from "./data/URL";

const ContentContext = createContext(null);
export const useContent = () => useContext(ContentContext);

export function ContentProvider({ children }) {
    //main data of the site
    const [partitions, setPartitions] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [adsSections, setAdsSections] = useState([]);
    const [otherData, setOtherData] = useState(DEFAULT_OTHER_DATA);
    const [currentSite, setCurrentSite] = useState({ domain: "clubok24.ru=clubok24.ru", fetching: false });

    //effect on change of the current site
    useEffect(() => {
        if (!currentSite.fetching) {
            store.requestMainData({
                data: { site: currentSite.domain },
                callback: () => setCurrentSite((prevstate) => ({ ...prevstate, fetching: true })),
            });
        }
    }, [currentSite.fetching]);

    const store = {
        //edit and create page's
        message(m = "Выполнено успешно.", s = "OK", t = "Статус:", e = 0) {
            const elemPopup = document.getElementById("popup_adcl24");
            const titlePopup = document.getElementById("title_adcl24");
            const statusPopup = document.getElementById("status_adcl24");
            const messagePopup = document.getElementById("message_adcl24");

            function hide() {
                elemPopup.classList.remove(style.popup_visible);
            }
            function show() {
                elemPopup.classList.add(style.popup_visible);
                setTimeout(() => hide(), 5000);
            }
            function setContent() {
                messagePopup.innerHTML = m;
                titlePopup.innerHTML = t;
                statusPopup.innerHTML = s;
                elemPopup.setAttribute("data-error", e);
            }

            hide();
            setTimeout(() => {
                setContent();
                show();
            }, 500);
        },
        reload(isReload = false) {
            if (isReload) store.blackout(style.reload_page, 0, 1, "visible");
            else store.blackout(style.reload_page, 0, 0, "hidden");
        },
        blackout(style, elem, opacity, visibility) {
            const darkScreenFilter = document.getElementsByClassName(style)[elem];
            if (darkScreenFilter === undefined) return null;
            darkScreenFilter.style.opacity = opacity;
            darkScreenFilter.style.visibility = visibility;
        },
        //main page
        statistics({ type = "all" } = {}) {
            setOtherData(DEFAULT_OTHER_DATA);
            axios.get(`${URL_COLLECTION.scripts.main.statistics}?type=${type}`).then((response) => {
                response.data.statisticsLoaded = true;
                setOtherData(response?.data);
            });
        },
        requestMainData({ type = "get", data = { site: "_" }, callback } = {}) {
            setPartitions([]);
            setKeywords([]);
            setAdsSections([]);

            const paramsMainData = $.param(data);
            axios
                .get(`${URL_COLLECTION.scripts.main.partitions}?type=${type}&${paramsMainData}`)
                .then((response) => {
                    const {
                        all_parts = {},
                        all_keywords = {},
                        all_ads_selectors = {},
                        not_viewed_comments,
                        not_viewed_messages,
                    } = response?.data || {};

                    const arr_parts = Object.values(all_parts);
                    const arr_keywods = Object.values(all_keywords);
                    const arr_ads_selections = Object.values(all_ads_selectors);

                    setPartitions(arr_parts);
                    setKeywords(arr_keywods);
                    setAdsSections(arr_ads_selections);
                    setOtherData((prevstate) => ({ ...prevstate, not_viewed_comments, not_viewed_messages }));

                    if (callback && callback instanceof Function) callback(response);
                });
        },
        changeSite(targetSite) {
            setCurrentSite({ domain: targetSite, fetching: false });
        },
    };

    const mainParameters = {
        //all paths
        currentSite,
        partitions,
        adsSections,
        keywords,
        otherData,
    };

    return (
        <ContentContext.Provider
            value={{
                mainParameters,
                store,
            }}
        >
            <Popup />
            {children}
        </ContentContext.Provider>
    );
}
