import { Link, useParams } from "react-router-dom";
import { useRecords } from "../../routes/Records/RecordsProvider";
import styles from "./Options.module.css";
import { useState } from "react";
import Item_FormCopy from "./Item_FormCopy";

const Item_options = ({ id, table, site }) => {
    const [viewForm, setViewForm] = useState(false);

    const { methods } = useRecords();
    const { options_item, view_item } = methods;

    let { path } = useParams();

    const isRecycle = path === "recycle";
    const isNews = table === "news";
    if (table === "adv") path = "/createadv/" + id;
    else if (table === "news") path = "/createnews/" + id;

    // recycle
    const restore_ = (
        <button
            data-action="fromRecycle"
            onClick={() => options_item({ action: 1, recycle: { id, table, value: 1 } })}
            className={styles.restore}
            title="Вернуть запись"
        >
            <span></span>
        </button>
    );
    const recycle_ = (
        <button
            onClick={() => options_item({ action: 0, recycle: { id, table, value: 0 } })}
            title="В корзину"
            className={styles.recycle}
        >
            <span></span>
        </button>
    );
    const delete_ = (
        <button
            onClick={() => options_item({ action: -1, recycle: { id, table, value: -1 } })}
            title="Удалить навсегда"
            className={styles.delete}
        >
            <span></span>
        </button>
    );

    //edit record
    const edit_ = (
        <Link title="Редактировать Запись" to={path} id={`${id}_edit`} className={styles.edit}>
            <span></span>
        </Link>
    );

    //fast view
    const view_ = (
        <button
            id={`${id}_view`}
            className={styles.view}
            title="Посмотреть пост в полном виде"
            onClick={() => view_item({ id, site, table })}
        >
            <span></span>
        </button>
    );

    //copy to another site
    const copy_ = (
        <>
            {viewForm && <Item_FormCopy id={id} site={site} setViewForm={setViewForm}/>}
            <button
                id={`${id}_copy`}
                className={styles.copy}
                title="Копировать запись на другой сайт"
                onClick={() => setViewForm(!viewForm)}
            >
                <span></span>
            </button>
        </>
    );

    return (
        <div className={styles.options_block}>
            {isRecycle ? delete_ : recycle_}
            {isNews && copy_}
            {!isRecycle && edit_}
            {isRecycle && restore_}
            {view_}
        </div>
    );
};
export default Item_options;
