import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./Input.module.css";
import uploadImage from "../../images/upload.png";
import DownloadMediaContainer from "./DownloadMediaContainer";
import MediaElement from "../../functions/MediaElement";

const baseStyle = {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "black",
    borderStyle: "dashed",
    backgroundColor: "#e0f2f7",
    color: "#000000",
    transition: "border .3s ease-in-out",
    userselect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
};
const activeStyle = {
    borderColor: "#2196f3",
};
const acceptStyle = {
    borderColor: "#00e676",
};
const rejectStyle = {
    borderColor: "#ff1744",
};

function UploadContainer({ name, className, ins, url, files, onInput, onChange, onDelete }) {
    const onDrop = (input) => {
        if (input && input.length) {
            input.map((file) => (file.preview = URL.createObjectURL(file)));
            onChange("replace");
            onInput(input);
        }
    };
    const buttonDelete = (name, item_id) => {
        return (
            <button
                data-id={item_id}
                data-name={name}
                className={styles.cancel_btn}
                title="Удалить медиа"
                onClick={onDelete}
            >
                &#10006;
            </button>
        );
    };
    const media = (file = {}, key, targetKey = "src") => {
        const { name, preview, datatype, type } = file;
        const item_id = !!!file.item_id ? datatype : file.item_id;
        if (preview === url) return;
        
        return (
            <div key={key} className={styles.fileMedia_wrapper}>
                {buttonDelete(name, item_id)}
                <MediaElement
                    videoparams={{ autoPlay: true, loop: true, muted: true }}
                    imageparams={{
                        loading: "lazy",
                    }}
                    media={{
                        [targetKey]: preview,
                    }}
                    isOnlyOne={true}
                    className={styles.fileMedia}
                />
            </div>
        );
    };

    const thumbs = files?.map((file, index) => {
        if (!file) return;
        return file && media(file, index, "blob");
    });

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: {
            "image/*": [],
            "video/*": [],
        },
    });
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    return (
        <section>
            <div className={styles.lCol}>
                <div className={styles.insc}>{ins}</div>
                <div
                    {...getRootProps({
                        style,
                    })}
                    className={styles.upload_container}
                >
                    {isDragReject && <div className={styles.rejectStyle}>Файл имеет непоходящий формат!</div>}
                    <input
                        name={name}
                        className={className}
                        type="file"
                        datatype="file"
                        multiple
                        {...getInputProps({})}
                    />
                    <img alt="undefinded_uploader" className={styles.upload_img} src={uploadImage} />
                    <h3>Выберите файл</h3>
                    <label>или перетащите его сюда</label>
                    <label>поддерживаемые типы файлов: изображения, гиф и видео-файлы</label>
                </div>
            </div>
            <DownloadMediaContainer />
            <aside className={style.fileArea}>{thumbs}</aside>
        </section>
    );
}

export default UploadContainer;
