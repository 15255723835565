import URL_COLLECTION from "../data/URL";
import { detectMime } from "./media";

export default function MediaElement(props) {
    const {
        videoparams = {},
        imageparams = {},
        media = {},
        events = {},
        className = "",
        callback = false,
        isOnlyOne = false,
        separator = ",",
        url = "",
    } = props;

    /* возвращает массив картинок или видео из строки ссылок, разделённой 'separator'ом */
    /* используется для слайдов swiper вместе с колбэк функцией, а также для вывода только одного заглавного фото */
    /* компонет работает как с массивами набора ссылок, так и со строками -/- */
    /* дата последнего изменения 27 03 23 г */

    /* TODO: переделать в функцию, принимающую строку, состоящую из одного ресурса.
    Определять mime type blob в асинхроне.
     */
    const { src = "", wwwlinks = "", blob = "", mime = false } = media;
    if (!wwwlinks && !src && !blob) return;

    const img = (mainattr = {}, attr = {}) => (
        <img
            {...mainattr}
            {...attr}
            onError={(e) => {
                e.currentTarget.src = e.currentTarget.src.replace(
                    URL_COLLECTION.media_items,
                    URL_COLLECTION.media_items_original
                );
            }}
        />
    );
    const video = (mainattr = {}, attr = {}) => (
        <video
            {...mainattr}
            {...attr}
            onError={(e) => {
                e.currentTarget.src = e.currentTarget.src.replace(
                    URL_COLLECTION.media_items,
                    URL_COLLECTION.media_items_original
                );
            }}
        />
    );

    function check_type_only_one(type, attr = false) {
        const mainAttr = {
            className: className,
            ...events,
        };

        let targetElement;

        switch (type) {
            case "image":
                const imageattr = {
                    ...imageparams,
                    ...mainAttr,
                };
                targetElement = img(imageattr, attr);
                break;
            case "video":
                const videoattr = {
                    ...videoparams,
                    ...mainAttr,
                };
                targetElement = video(videoattr, attr);
            default:
                break;
        }
        return targetElement;
    }
    function prepareToArray(string = false, separator) {
        const array = (string && (Array.isArray(string) ? string : string?.split(separator))) || [];
        array?.filter(Boolean);
        return array;
    }
    const extension = (source = "") => {
        const sourcearray = source?.split(".");
        const sourceExtension = sourcearray[sourcearray?.length - 1];
        return sourceExtension;
    };

    function mapSources(array = [], targetarray = [], isExtension) {
        array?.map((element, index) => {
            if (!element) return;

            let targetElement;
            let currentmime = "image";

            const attr = {
                ...imageparams,
                src: element,
                alt: "MEDIADATA - " + index,
            };

            if (!isExtension) targetElement = check_type_only_one(currentmime, attr, index);
            else currentmime = detectMime(extension(element));

            targetElement = check_type_only_one(currentmime, attr, index);
            targetarray.push(targetElement);
        });
    }

    const source_first = prepareToArray(src, separator);
    const source_second = prepareToArray(wwwlinks, separator);
    const source_third = prepareToArray(blob, separator);

    source_first?.forEach((element, index) => {
        if (!element) return;
        source_first[index] = url + element;
    });

    const sourses = [];

    mapSources(source_first, sourses, true);
    mapSources(source_second, sourses, false);
    mapSources(source_third, sourses, true);

    function callbackAll(sourcesarray) {
        sourcesarray.map((element, index) => {
            return callback ? callback(element, index) : element;
        });
    }

    if (isOnlyOne) return sourses[0];
    else return callbackAll(sourses);
}
