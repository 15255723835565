import { useLocation } from "react-router-dom";
import { useState } from "react";
import style from "./Input.module.css";
import InputText from "./InputText";
import { useCreate } from "../../routes/Creator/CreateProvider";

export default function DownloadMediaContainer() {
    const [errorMessage, setErrorMessage] = useState(false);
    const [downloadableLink, setDownloadableLink] = useState("");

    const { creator } = useCreate();
    const { onDropFiles, onChangeData } = creator;

    let { pathname } = useLocation();
    pathname = pathname?.split("/")[1] || "home";

    const onChangeInput = (event) => setDownloadableLink(event.target.value);

    function validURL() {
        if (!downloadableLink) return setErrorMessage("Введите ссылку!");
        let url = downloadableLink;
        const res = downloadableLink.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        if (!/^https?:\/\//i.test(downloadableLink)) url = "http://" + downloadableLink;
        if (res) {
            try {
                _fetchMedia(url);
            } catch (e) {
                return setErrorMessage("Ошибка загрузки медиафайла.");
            }
        } else return setErrorMessage("Ссылка некорректна!");
    }

    function _fetchMedia(url) {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const newName = "downloadedFile";
                const downloadedFile = new File([blob], newName);
                downloadedFile.path = newName;
                downloadedFile.preview = URL.createObjectURL(downloadedFile);
                onDropFiles({ acceptedFiles: [downloadedFile], pathname });
                onChangeData({ value: "replace", name: "mediaReplace", pathname: "currentItem" });
                setErrorMessage(false);
                setDownloadableLink("");
            })
            .catch((e) => {
                setErrorMessage(
                    "Ошибка загрузки медиафала - сервер не отвечает, либо передача запрещена (политика CORS)."
                );
            });
    }

    return (
        <div className={style.links_media_input_container}>
            <div className={style.lmic_input_container}>
                <InputText
                    value={downloadableLink}
                    onChange={onChangeInput}
                    name="downloadable_container"
                    max={1000}
                    input_insc="Ссылка на медиафайл"
                    errorMessage={errorMessage}
                    type="text"
                    input_plc="https://example.ru/image.png"
                />
                <button
                    onClick={() => (downloadableLink ? validURL() : "")}
                    className={`${style.lmic_button} ${!downloadableLink ? style.noValid : ""}`}
                >
                    Добавить
                </button>
            </div>
        </div>
    );
}
