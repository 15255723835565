import { useContent } from "../../ContentProvider";
import Keywords from "../../components/Input/Keywords";
import Selector from "../../components/Input/Selector";
import PartitionsWrapper from "../../components/Partitions/PartitionsWrapper";
import Statistics from "../../components/Statistics/Statistics";
import stylesCreate from "../../css/Create.module.scss";
import style from "../../css/Pages.module.css";
import { SITES } from "../../data/DEFAULT_DATA";

const Main = () => {
    const { store, mainParameters } = useContent();
    const { currentSite, partitions, adsSections } = mainParameters;
    const { clearData, changeSite } = store;

    return (
        <>
            <div className={style.title_opt}>Главная страница</div>
            <div className={style.content_oneitemPage}>
                <form target="dummyframe" onReset={clearData}>
                    <Statistics />
                    <div className={` ${stylesCreate.title_global_settings}`}>Настройки сайта</div>
                    <div className={stylesCreate.global_settings}>
                        <Keywords isActions={false} />
                        <div className={stylesCreate.target_site_row}>
                            <Selector
                                title="Целевой сайт"
                                value={currentSite.domain}
                                onChange={(e) => changeSite(e.target.value)}
                                options={SITES || []}
                            />
                        </div>
                        {currentSite.fetching ? (
                            <div className={stylesCreate.main_partitions}>
                                <PartitionsWrapper
                                    data={partitions}
                                    title="2. Рубрики сайта"
                                    titleInput="Добавить рубрику сайта"
                                    table="partitions"
                                />
                                <PartitionsWrapper
                                    data={adsSections}
                                    title="3. Разделы рекламы"
                                    titleInput="Добавить раздел сайта"
                                    table="adv_sections"
                                    isSubpartitions={false}
                                />
                            </div>
                        ) : (
                            <div className={stylesCreate.loading_main} />
                        )}
                    </div>
                </form>
                <iframe title="#" name="dummyframe" id="dummyframe" style={{ display: "none" }}></iframe>
            </div>
        </>
    );
};
export default Main;
