import React from "react";
import style from "./Statistics.module.css";

export default function StatisticsItem({ title = false, heading = "", value = "" }) {
    return (
        <div className={style.statistics_item} title={title}>
            <div className={style.heading}>{heading}</div>
            <div className={style.value}>{value}</div>
        </div>
    );
}
