import { Dispatch, SetStateAction, SyntheticEvent, useState } from "react";
import { useContent } from "../../../ContentProvider";
import transliterate from "../../../functions/transliterator";
import InputText from "../../Input/InputText";
import { validatePartition } from "../partitions.helpers";
import { TParamsPartitions } from "../partitions.interface";
import style from "../Partitions.module.scss";

interface IAddPartition {
    title: string;
    value: string;
    paramsPartitions: TParamsPartitions;
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    setIsSaveParts: Dispatch<SetStateAction<string | boolean>>;
}

type OnAddCallBack = {
    data?: {
        message: string;
    };
};

export default function AddPartition({
    title,
    value,
    paramsPartitions,
    onChange,
    setIsSaveParts,
}: IAddPartition) {
    const [error, setError] = useState<boolean | string>(false);
    //@ts-ignore
    const { store, mainParameters } = useContent();
    const { requestMainData } = store;
    const { currentSite } = mainParameters;

    function onAdd() {
        const iteratedValue = value.trim();
        const isValidate = validatePartition(iteratedValue);
        if (typeof isValidate === "string") return setError(isValidate);

        setError(false);

        const transliteratedWord = transliterate(iteratedValue);

        const callback = ({ data }: OnAddCallBack) => {
            const error = () => {
                setIsSaveParts(" - Ошибка сохранения данных");
                setTimeout(() => setIsSaveParts(false), 8000);
            };
            const ok = () => {
                setIsSaveParts(" - Изменения сохранены!");
                setTimeout(() => setIsSaveParts(false), 8000);
            };

            
            if (!data) return error();

            if (data?.message) {
                const status_message = data?.message?.split(",") || [];
                if (status_message && status_message[1] === "ok") ok();
            } else error();
        };

        requestMainData({
            type: "set",
            data: {
                table: paramsPartitions.table,
                site: currentSite.domain,
                parent_id: paramsPartitions.parent_id,
                current: `${transliteratedWord}=${iteratedValue}`,
            },
            callback,
        });
    }

    return (
        <div className={style.partition_create}>
            <div>
                <InputText
                    //@ts-ignore
                    errorMessage={error}
                    //@ts-ignore
                    onChange={onChange}
                    name="titleAdv"
                    value={value}
                    max={200}
                    type="text"
                    required
                    input_insc={title}
                    input_plc="Новая рубрика..."
                />
            </div>
            <button onClick={onAdd} type="button" name="partitions" className={style.partition_create_button}>
                Добавить
            </button>
        </div>
    );
}
