import Searcher from "../components/Searcher";
import style from "../css/Menu.module.css";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import URL_COLLECTION from "../data/URL";
import { useContent } from "../ContentProvider";

const activeStyle = `${style.navigationTarget} ${style.navigationTarget_active}`;
const inactiveStyle = style.navigationTarget;
const className = ({ isActive }) => (isActive ? activeStyle : inactiveStyle);

const Menu = ({ handleLogout, width = 639, maxWidth }) => {
    const { mainParameters } = useContent();
    const { otherData } = mainParameters || {};
    const { not_viewed_comments, not_viewed_messages } = otherData || {};

    const { main, video, news } = URL_COLLECTION.sites;

    const [view, setView] = useState(false);

    useEffect(() => {
        if (width > maxWidth) setView(true);
    }, [width]);

    return (
        <div className={style.mainNavigationsBar}>
            <div className={style.mainNavRow}>
                <div onClick={() => setView(!view)} className={style.viewPartitionsBar_button}></div>
                <div className={style.titleSite}>Админ панель</div>
            </div>
            {view && (
                <nav className={style.partitionsBar}>
                    <Searcher />
                    <NavLink data-action="edit" to="/" id={style.main} className={className}>
                        Главная
                    </NavLink>
                    <NavLink data-action="edit" to="news" id={style.news} className={className}>
                        Новости
                    </NavLink>
                    <NavLink data-action="edit" to="createnews" className={className}>
                        <span className={style.prefix}></span>Добавить новость
                    </NavLink>
                    <NavLink data-action="edit" to="comments" className={className}>
                        <span className={style.prefix}></span>
                        Комментарии
                        {not_viewed_comments && not_viewed_comments !== "0" && (
                            <span className={style.count}>+{not_viewed_comments}</span>
                        )}
                    </NavLink>
                    <NavLink data-action="edit" to="adv" id={style.amalker} className={className}>
                        Реклама
                    </NavLink>
                    <NavLink data-action="edit" to="createadv" className={className}>
                        <span className={style.prefix}></span>Добавить рекламу
                    </NavLink>
                    <NavLink data-action="edit" to="recycle" id={style.recycle} className={className}>
                        Удалённые записи
                    </NavLink>
                    <NavLink data-action="edit" to="messages" id={style.managing} className={className}>
                        Сообщения
                        {not_viewed_messages && not_viewed_messages !== "0" && (
                            <span className={style.count}>+{not_viewed_messages}</span>
                        )}
                    </NavLink>
                    {/* <NavLink to="zenno" id={style.zennolab} className={className}>
                        ZennoLab
                    </NavLink>
                    <NavLink to="createzenno" className={className}>
                        <span></span> Добавить момент (OK.ru)
                    </NavLink> */}
                    <a
                        href="https://metrika.yandex.ru/dashboard?id=91532407"
                        target="_blank"
                        rel="noopener noreferrer"
                        id={style.site_yam}
                        className={style.navigationTarget}
                    >
                        Яндекс.Метрика
                    </a>
                    <a
                        href="https://webmaster.yandex.ru/site/https:clubok24.ru:443/dashboard/"
                        target="_blank"
                        rel="noopener noreferrer"
                        id={style.site_yawm}
                        className={style.navigationTarget}
                    >
                        Яндекс.Вебмастер
                    </a>
                    <a
                        href="https://cp.sweb.ru/hosting/file_manager"
                        target="_blank"
                        rel="noopener noreferrer"
                        id={style.site_sweb}
                        className={style.navigationTarget}
                    >
                        Хостинг SWEB
                    </a>
                    <a
                        href="https://cpa.tl/"
                        target="_blank"
                        rel="noopener noreferrer"
                        id={style.site_tl}
                        className={style.navigationTarget}
                    >
                        Trafic Light
                    </a>
                    <a
                        href={main}
                        target="_blank"
                        rel="noopener noreferrer"
                        id={style.site}
                        className={style.navigationTarget}
                    >
                        CLUBOK24.RU
                    </a>
                    <a
                        href={video}
                        target="_blank"
                        rel="noopener noreferrer"
                        id={style.site}
                        className={style.navigationTarget}
                    >
                        VIDEO.CLUBOK24.RU
                    </a>
                    <a
                        href={news}
                        target="_blank"
                        rel="noopener noreferrer"
                        id={style.site}
                        className={style.navigationTarget}
                    >
                        SUPER-STORY.RU
                    </a>
                    <div
                        className={style.navigationTarget}
                        id={style.logout}
                        onClick={handleLogout}
                        title="Выйти из аккаунта"
                    >
                        Выйти из аккаунта
                    </div>
                    <a
                        href={URL_COLLECTION.url_scripts + `phpinfo.php`}
                        target="_blank"
                        rel="noopener noreferrer"
                        id={style.php}
                        className={style.navigationTarget}
                    >
                        PHP INFO()
                    </a>
                </nav>
            )}
        </div>
    );
};
export default Menu;
