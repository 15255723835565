import style from "../css/Pages.module.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import stringToColor from "../functions/stringToColor";
import URL_COLLECTION from "../data/URL";

export default function Messages(props) {
    const url_get_contacts = URL_COLLECTION.scripts.main.get_contacts;

    const [fetching, setFetching] = useState(false);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        if (!fetching) {
            axios
                .get(`${url_get_contacts}?type=getMessages`, {
                    headers: {
                        "Content-Type": "text/html",
                    },
                })
                .then((r) => {
                    const values = Object.values(r.data);
                    setMessages([...values]);
                })
                .catch((r) => {
                    console.error(r);
                })
                .finally(() => {
                    setFetching(true);
                });
        }
    }, [fetching]);
    useEffect(() => {
        let timer;
        if (fetching) {
            timer = setInterval(() => {
                setFetching(false);
            }, 10000);
        }
    }, [fetching]);

    function mapMessages() {
        if (!messages) return;
        if (!Array.isArray(messages)) return;
        function onDeleteMessage(id) {
            axios
                .get(`${url_get_contacts}?type=deleteMessage&id=${id}`, {
                    headers: {
                        "Content-Type": "text/html",
                    },
                })
                .then((r) => {
                    const values = Object.values(r.data);
                    setMessages([...values]);
                })
                .catch((r) => {
                    console.error(r);
                })
                .finally(() => {
                    setFetching(true);
                });
        }
        return messages.map((elem, index) => {
            const { id, name, email, message, timestamp } = elem;
            return (
                <div key={index} data-id={id} className={style.message_container}>
                    <div className={style.logo_user} style={{ backgroundColor: stringToColor(name) }}>
                        {name[0]}
                    </div>
                    <div className={style.message_block}>
                        <div className={style.name}>
                            {name}
                            <span>{timestamp}</span>
                            <span className={style.identificator}>#{id}</span>
                        </div>
                        <div className={style.message}>&nbsp;{message}</div>
                        <div className={style.email}>
                            E-mail:&nbsp;
                            <a
                                style={{ color: stringToColor(name) }}
                                href={`mailto:${email}`}
                                target="_blank"
                                rel="noopener noreferer"
                            >
                                {email}
                            </a>
                        </div>
                    </div>
                    <span
                        className={style.delete}
                        onClick={() => {
                            onDeleteMessage(id);
                        }}
                    ></span>
                </div>
            );
        });
    }

    return (
        <>
            <div className={style.title_opt}>Сообщения пользователей</div>
            <div className={style.content_oneitemPage}>
                <div className={style.messages_wrapper}>
                    {!fetching ? "Загрузка..." : ""}
                    {messages.length === 0 && fetching ? <h3>Сообщения отсутствуют</h3> : mapMessages()}
                </div>
            </div>
        </>
    );
}
