import React from "react";
import { useContent } from "../../ContentProvider";
import style from "./Statistics.module.css";
import StatisticsItem from "./StatisticsItem";
import { today } from "../../data/DEFAULT_DATA";
import { useEffect } from "react";

export default function Statistics() {
    const { mainParameters, store } = useContent();
    const { otherData } = mainParameters;

    useEffect(() => {
        store.statistics();
    }, [window.location.href]);

    if (!otherData) return;
    const { viewsCount, commentsCount, ratingCount, createdAdvCount, createdNewsCount } = otherData || {};

    return (
        <div className={style.statistics_wrapper}>
            <div title={`Статистика за ${today}`} className={style.head}>
                Статистика за сутки
            </div>
            {!otherData.statisticsLoaded ? (
                <div className={style.row}>Загрузка...</div>
            ) : (
                <>
                    <div className={style.row}>
                        <StatisticsItem
                            heading="Просмотры записей"
                            title="Общее количество просмотров за сутки"
                            value={viewsCount}
                        />
                        <StatisticsItem
                            heading="Оценки"
                            title="Общее количество оценок за сутки"
                            value={ratingCount}
                        />
                        <StatisticsItem
                            heading="Комментарии"
                            title="Общее количество комментариев за сутки"
                            value={commentsCount}
                        />
                    </div>
                    <div className={style.row}>
                        <StatisticsItem
                            heading="Создано записей"
                            title="Общее количество созданных записей за сутки"
                            value={createdNewsCount}
                        />
                        <StatisticsItem
                            heading="Создано рекламных записей"
                            title="Общее количество созданных рекламных записей за сутки"
                            value={createdAdvCount}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
