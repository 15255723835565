import React from "react";
import { useLocation, useParams } from "react-router-dom";
import InputCheckbox from "../../components/Input/InputCheckbox";
import InputText from "../../components/Input/InputText";
import MultipleCheckbox from "../../components/Input/MultipleCheckbox";
import TrippleCheckbox from "../../components/Input/TrippleCheckbox";
import UploadContainer from "../../components/Input/UploadContainer";
import Create_options from "../../components/Options/Create_options";
import Filter from "../../components/Sorting/Filter/Filter";
import { useContent } from "../../ContentProvider";
import style from "../../css/Pages.module.css";
import { POSITIONS_ADV } from "../../data/DEFAULT_DATA";
import URL_COLLECTION from "../../data/URL";
import { useCreate } from "./CreateProvider";

const CreateAdv = (props) => {
    let { pathname } = useLocation();
    pathname = pathname?.split("/")[1] || "home";

    const { edit_id } = useParams();
    const titlePage = edit_id ? "Редактирование баннера №" + edit_id : "Добавить рекламу";

    const { mainParameters } = useContent();
    const { adsSections } = mainParameters;

    const { data, files, creator } = useCreate();
    const { onChangeData, onDeleteMedia, onDropFiles } = creator;
    const DP = data[pathname];

    return (
        <>
            <div className={style.title_opt}>{titlePage}</div>
            <div className={style.content_oneitemPage}>
                <InputText
                    name="titleAdv"
                    value={DP["titleAdv"]}
                    onChange={(event) =>
                        onChangeData({
                            event: event,
                            pathname,
                            name: "titleAdv",
                            dublicateName: "adv_admin",
                            type: "transliterated_string",
                        })
                    }
                    required_class="_req"
                    max={65535}
                    type="text"
                    emoji
                    city
                    date
                    isInsertButtons
                    required
                    input_insc="Название рекламы"
                    input_plc="Реклама магазина на Avito"
                />
                <InputText
                    name="footnote"
                    value={DP["footnote"]}
                    onChange={(event) => onChangeData({ event, pathname, name: "footnote" })}
                    emoji
                    city
                    date
                    isInsertButtons
                    max={300}
                    type="text"
                    input_insc="Сноска к названию"
                    input_plc="Невероятно обогатятся те, кто родился..."
                />
                <div className={style.row_selectors}>
                    <Filter
                        prefix=""
                        head="Раздел"
                        title="Выбрать раздел рекламы"
                        onClick={(value) => onChangeData({ name: "partition", value, pathname })}
                        options={adsSections}
                        selectedOption={DP["partition"]}
                    />
                    <InputCheckbox
                        name="insc_ads"
                        id="insc_ads"
                        value={DP["insc_ads"]}
                        onChange={(event) => onChangeData({ name: "insc_ads", event, pathname })}
                        insc='Надпись "реклама"'
                    />
                    <TrippleCheckbox
                        name="is_trash"
                        id="is_trash"
                        value={DP["is_trash"]}
                        onChange={(event) => onChangeData({ name: "is_trash", event, pathname })}
                        options={["Обыкн.", "Треш", "Обыкн/треш", "Статья"]}
                        insc="Тип рекламы"
                    />
                </div>
                <div className={style.row_selectors}>
                    <MultipleCheckbox
                        name="position"
                        id="position"
                        values={DP["position"]}
                        onChange={(value) => onChangeData({ name: "position", value, pathname })}
                        options={POSITIONS_ADV}
                        title="Позиции рекламы"
                    />
                </div>
                <UploadContainer
                    name="media"
                    files={files}
                    url={URL_COLLECTION.media_items}
                    onChange={(value) =>
                        onChangeData({ value, name: "mediaReplace", pathname: "currentItem" })
                    }
                    onDelete={(value) => onDeleteMedia(value, pathname)}
                    onInput={(input) => onDropFiles({ acceptedFiles: input, pathname })}
                    className="_req media"
                    ins="Баннер (изображение/видео)*"
                />
                <InputText
                    name="linkAdv"
                    value={DP["linkAdv"]}
                    onChange={(event) => onChangeData({ name: "linkAdv", event, pathname })}
                    required
                    required_class="_req"
                    type="text"
                    input_insc="Ссылка на сайт"
                    input_plc="www.avito.ru/profile"
                    max={1000}
                />
                <InputText
                    name="note"
                    value={DP["note"]}
                    onChange={(event) => onChangeData({ name: "note", event, pathname })}
                    required
                    type="text"
                    input_insc="Заметка"
                    input_plc="Текст заметки..."
                    max={255}
                />
                <Create_options />
            </div>
        </>
    );
};
export default React.memo(CreateAdv);
