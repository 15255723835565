import Pagination from "../../components/Pagination";
import SortingPanel from "../../components/Sorting/SortingPanel";
import style from "../../css/Pages.module.css";
import { PAGE_TITLES } from "../../data/DEFAULT_DATA";
import { useRecords } from "./RecordsProvider";

export default function Records() {
    const { params, content } = useRecords();
    const { search, path } = params;
    const { items, isLoading } = content;

    function titleSearch(search) {
        const onResetSearch = () => (window.location.search = "");
        return (
            <div className={style.search_title}>
                <div>
                    Результаты поиска по запросу&nbsp;<i style={{ backgroundColor: "yellow" }}>{search}</i>
                </div>
                <button onClick={onResetSearch}>Отменить</button>
            </div>
        );
    }

    return (
        <>
            <div className={style.title_opt}>{search ? titleSearch(search) : PAGE_TITLES[path]}</div>
            <SortingPanel />
            <div className={style.content_oneitemPage}>
                {items.length === 0 && !isLoading ? <h3>Записи отсутствуют</h3> : ""}
                {isLoading ? "Загрузка..." : ""}
                <Pagination />
            </div>
        </>
    );
}
