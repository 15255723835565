import React from "react";
import { useContent } from "../../ContentProvider";
import styles from "./Sorting.module.css";
import { useRecords } from "../../routes/Records/RecordsProvider";

export default function ReloadClearButtons({}) {
    const { methods } = useRecords();
    const { loading_items, change_search_params } = methods;

    const { store } = useContent();
    const { requestMainData } = store;

    function onClickButtonClear() {
        change_search_params({ clear: true });
        requestMainData({});
    }

    return (
        <div className={styles.child_range}>
            <button className={styles.reload} onClick={loading_items} title="Обновить" />
            <button className={styles.clear} onClick={onClickButtonClear} title="Очистить" />
        </div>
    );
}
