import { RECORD_FRAMES, TYPES_ADV } from "../../data/DEFAULT_DATA";
import URL_COLLECTION from "../../data/URL";
import MediaElement from "../../functions/MediaElement";
import { useRecords } from "../../routes/Records/RecordsProvider";
import style from "./Viewer.module.css";

export default function Viewer() {
    const { content, methods } = useRecords();
    const { viewRecord } = content;
    const { close_item } = methods;

    //default data
    const { id, media, path, view, timestamp } = viewRecord || {};
    //news data
    const {
        desc_news,
        desc_meta,
        keywords,
        isVideoFrame,
        link,
        part,
        title,
        rating,
        date_local,
        time_local,
        site,
    } = viewRecord || {};

    //adv data
    const { adv_admin, checked, linkAdv, position, partition, titleAdv, footnote, note, is_trash } =
        viewRecord || {};

    const isNews = path === "news";
    const isAdv = path === "adv";
    const partitionNews = isNews ? part?.split("=")[1] : "";
    const ratingLength = isNews ? Object.values(rating || {})?.length || "0.0" : "";
    const partitionAdv = isAdv ? partition?.split("=")[1] : "";

    function averageValueRating(detailRating) {
        if (!detailRating) return false;
        const lengthDetailRating = detailRating?.length || 1;

        const initialValue = 0;
        const sumWithInitial = detailRating?.reduce(
            (accumulator, currentValue) => accumulator + parseInt(currentValue?.score, 10) || 1,
            initialValue
        );
        return (sumWithInitial / lengthDetailRating)?.toFixed(1);
    }

    function aboutSpan(title, text, activeDot = true) {
        return (
            <div>
                <span className={style.about_title}>{title}</span>&nbsp;
                {text && <span>{text}</span>} {activeDot ? "·" : ""}
            </div>
        );
    }

    return (
        <div className={style.main_view_wrapper}>
            <div onClick={close_item} className={style.back_view_wrapper}></div>
            <div className={style.content_view_wrapper}>
                <div className={style.top_content_view}>
                    <div className={style.top_name_view}>Запись №{id}</div>
                    <div onClick={close_item} className={style.top_cancel_view}></div>
                </div>
                <div className={style.center_content_view}>
                    <div className={style.about_viewer}>
                        Примечание: конечный вид записи может значительно отличаться от того, что изображено в
                        данном блоке.
                    </div>
                    <div className={style.center_title_view}>{title || titleAdv}</div>
                    <div className={style.center_about_view}>
                        {note && aboutSpan("Заметка:", note)}
                        {footnote && aboutSpan("Подпись:", footnote)}
                        {aboutSpan("Раздел:", partitionNews || partitionAdv)}
                        {view && aboutSpan(isAdv ? "Клики" : "Просмотры:", view)}
                        {!!rating &&
                            aboutSpan(
                                "Рейтинг:",
                                `${averageValueRating(
                                    Object.values(rating || {}) || []
                                )} из ${ratingLength} оц.`
                            )}
                        {position && aboutSpan("Позиции показа:", position)}
                        {is_trash && aboutSpan("Тип контента:", TYPES_ADV[is_trash]?.split("=")[1])}
                        {date_local && aboutSpan("Дата публикации:", date_local + " " + time_local)}
                        {timestamp && aboutSpan("Дата создания:", timestamp)}
                        {checked && aboutSpan("Состояние:", checked === "1" ? "ВКЛ" : "ВЫКЛ")}
                        {isVideoFrame &&
                            aboutSpan("Рамка записи:", RECORD_FRAMES[isVideoFrame]?.split("=")[1])}
                        {keywords && aboutSpan("Ключевые слова:", keywords)}
                        {desc_meta && aboutSpan("Описание в поисковике:", desc_meta)}
                        {aboutSpan("Сылка:", site ? `"https://${site}/` : "" + link, false)}
                    </div>
                    <hr id={style.hr_top} className={style.hr} />
                    <div className={style.center_media_view}>
                        <div className={style.slide_media_container}>
                            <MediaElement
                                url={URL_COLLECTION.media_items}
                                videoparams={{ autoPlay: true, loop: true, muted: true }}
                                imageparams={{
                                    loading: "lazy",
                                }}
                                media={{
                                    src: media,
                                }}
                                events={{
                                    onMouseMove: function (e) {
                                        e.target.nodeName === "VIDEO" && e.target.play();
                                    },
                                }}
                                isOnlyOne={true}
                                className={style.imageItem_forView}
                            />
                        </div>
                    </div>
                    <hr id={style.hr_bottom} className={style.hr} />
                </div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: desc_news || linkAdv,
                    }}
                    className={style.bottom_content_view}
                />
            </div>
        </div>
    );
}
